import { useEffect } from 'react';
import React from 'react'
import classes from "./Orgdvlp.module.css"
import Footer from "../FOOTER/Footer";
import Navbar from "../NAVBAR/Navbar";
import { Link } from 'react-router-dom';
const Orgdvlp = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
  return (
    <>
    <Navbar />
    
    <div className={classes.maincontainer}>
        <div className={classes.container}>
                    <div className={classes.description}>

                        <h2 className={classes.h}>Job Bridge India</h2>
                        
                        <p className={classes.hh}>HR Consulting ➢  <span className={classes.clr}>Organisational Development</span></p>
                       
                        <p className={classes.hhh}>Where we specialize in transforming businesses into agile, adaptive, and high-performance entities. Our HR Consulting Services cover a spectrum of key areas essential for organisational success.</p>
                    </div>
        </div> 
        <div className={classes.frist}>
                    <div className={classes.points}>
                    <Link to="/hrStrategy" className={classes.point1}>•HR Strategy</Link>
                        <Link to="/talentmanagement" className={classes.point1}>•Talent Management</Link>
                        <Link to="/org" className={classes.point1}>•Organisational Development</Link>
                        <Link to="/hrservicesdelivery" className={classes.point1}>•HR Service Delivery</Link>

                    </div>

                </div>
                <div className={classes.second}>
                    <div className={classes.container1}>

                    </div>
                    <div className={classes.description1}>
                        <h1 className={classes.hr1}>Organisational Development- Services</h1>
                        <p className={classes.hr2}>Explore our tailored<span className={classes.clr2}>Organisational Development</span><span className={classes.clr3}>encompassing organisation</span></p>
                        
                        <p className={classes.hr3}>Embrace change and drive organisational growth with our organisational development services. We work alongside you to implement interventions that enhance teamwork, leadership, and overall employee performance. Create an environment that adapts to change seamlessly and fosters a culture of innovation, ensuring your organisation is ready for whatever challenges come its way.</p>
                        
                        {/* <button className={classes.cB}>
                                <Link to="/contact" className={classes.cB}>Contact Us</Link>
                            </button> */}
                    </div>
                </div>
               
                <div className={classes.third}>
                    <p className={classes.third1}>Organisational Assessment</p>
                    
                    <p className={classes.third2}>Cultivate a positive and purpose-driven organisational culture with targeted interventions that align with your values and strategic objectives.</p>
                    <p className={classes.third3}>Values</p>
                    
                    <p className={classes.third4}>Define and reinforce core values that serve as the foundation for your organisational identity and guide decision-making at all levels.</p>
                    <p className={classes.third3}>Change Management</p>
                    <p className={classes.third4}>Navigate change seamlessly with our proven change management strategies, ensuring a smooth transition and sustained employee engagement.</p>
                     <p className={classes.third3}>Organisational Learning</p>
                     <p className={classes.third4}>Foster a culture of continuous learning, empowering your workforce to adapt to evolving industry trends and stay ahead of the curve.</p>
                    <p className={classes.third3}>Restructuring</p>
                    <p className={classes.third4}>Optimize your organisational structure for enhanced efficiency, agility, and responsiveness to market dynamics.</p>
                    <p className={classes.third3}>Innovation</p>
                    <p className={classes.third4}>Cultivate a culture of innovation that encourages creative thinking, problem-solving, and the pursuit of groundbreaking ideas.</p>
                    <p className={classes.third3}>Leadership Development</p>
                    <p className={classes.third4}>Develop strong, adaptive leaders who inspire and guide their teams toward achieving strategic goals and driving organisational success.</p>
                    <p className={classes.third3}>Team Building</p>
                    <p className={classes.third4}>Enhance teamwork and collaboration through tailored team-building initiatives, fostering a cohesive and high-performing work environment.</p>
                    <p className={classes.third3}>Organisational Climate</p>
                    <p className={classes.third4}>Assess and improve the organisational climate to create a positive workplace atmosphere that enhances employee well-being and productivity.</p>
                    <p className={classes.third3}>Morale</p>
                    <p className={classes.third4}>Boost employee morale through targeted strategies and initiatives, creating a motivated and engaged workforce.</p>
                    
                    <p className={classes.third5}>At Job Bridge India, we understand the pivotal role Organisational Development plays in ensuring your business stays ahead in today's dynamic landscape. Partner with us to create a resilient and future-ready organisation.</p>
                </div>
                
                <div className={classes.nine}>
                
                        <div className={classes.nine1}>
                            <div className={classes.nine2}>
                                <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
                                <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
                            </div>
                            
                        </div>
            
                </div>
        
    </div>
    <Footer />
     </>
  )
}

export default Orgdvlp