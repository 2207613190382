import Footer from "../FOOTER/Footer";
import Navbar from "../NAVBAR/Navbar";
import classes from "./EnquiryForm.module.css"

const EnquiryForm = () =>{
    return(
        <div className={classes.mainContainer}>
            <Navbar/>
            <div className={classes.formContainer}>
                <h1>Any Questions ? We are here to Help !</h1>
                <form>
                    <div className={classes.row}>
                    <input type="text" placeholder="Your Full Name"/>
                    <input type="text" placeholder="Your Company Name"/>
                    </div>
                    
                    <div className={classes.row}>
                    <input type="text" placeholder="Email Address"/>
                    <input type="text" placeholder="Phone Number"/>
                    </div>
                    
                    <div className={classes.row}>
                    <select>
                        <option>Select Your Designation</option>
                        <option>HR</option>
                        <option>Founder/CEO/Director</option>
                        <option>COO</option>
                        <option>CIO</option>
                        <option>CMO</option>
                        <option>Other</option>
                    </select>
                    <select>
                        <option>I am looking to hire</option>
                        <option>I am looking for a job</option>
                    </select>
                    </div>
                    

                    <textarea placeholder="Describe Your Requirement" />

                    <button>Submit</button>
                </form>
            </div>
            <Footer/>
        </div>
    );
};
export default EnquiryForm;