
// import { useEffect, useState } from "react";
// import Navbar from "../../NAVBAR/Navbar";
// import Footer from "../../FOOTER/Footer";
// import axios from "axios";
// import classes from "./PostedJobs.module.css";

// const PostedJobs = () => {
//     const[jobs,setJobs]=useState()

//     useEffect(()=>{
//         const fetchAllJobs = async () =>{
//             try{
//                 const res = await axios.get("http://localhost:8800/feturedJob");
//                 console.log(res)
//                 setJobs(res.data)
//             }catch(err){
//                 console.log(err)
//             }
//         }
//         fetchAllJobs();
//     },[]);
//   return (
//     <>
//     <Navbar/>
//     <div className={classes.container}>
//         <center> <h1>Here list of Posted Jobs</h1></center>
       

//         {jobs?.map((job,index)=>{
//             return <div key={index}>
                

//                 <p>{job.ID}</p>
//                 <p>{job.JOB_TITLE}</p>
//                 <p>{job.JOB_TYPE}</p>
//                 <p>{job.POSITION_OPEN}</p>
//                 <p>{job.JOB_TYPE}</p>
//                 <p> {job.CITY}</p>
//             </div>
//         })}

//     </div>
//     <Footer/>
//     </>
//   )
// }

// export default PostedJobs


import { useEffect, useState } from "react";
import Navbar from "../../NAVBAR/Navbar";
import Footer from "../../FOOTER/Footer";
import axios from "axios";
import classes from "./PostedJobs.module.css";

const PostedJobs = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchAllJobs = async () => {
            try {
                const res = await axios.get("https://jobbridgeindia.com/feturedJob");
                setJobs(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchAllJobs();
    }, []);

    const deleteJob = async (id) => {
        try {
            await axios.delete(`https://jobbridgeindia.com/deleteJob/${id}`);
            setJobs(jobs.filter(job => job.ID !== id));
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <h1 className={classes.title}>Posted Jobs</h1>
                <div className={classes.cardsContainer}>
                    {jobs?.map((job, index) => (
                        <div className={classes.card} key={index}>
                            <div className={classes.cardContent}>
                                <h2 className={classes.jobTitle}>{job.JOB_TITLE}</h2>
                                <p className={classes.jobDetail}><span className={classes.detailLabel}>Type:</span> {job.JOB_TYPE}</p>
                                <p className={classes.jobDetail}><span className={classes.detailLabel}>Positions:</span> {job.POSITION_OPEN}</p>
                                <p className={classes.jobDetail}><span className={classes.detailLabel}>City:</span> {job.CITY}</p>
                            </div>
                            <button className={classes.deleteButton} onClick={() => deleteJob(job.ID)}>
                                <span className={classes.deleteIcon}>×</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PostedJobs;