// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import Testimonials from "./Testimonials";
// import star5 from "./Images/5star.png";
// import star4 from "./Images/4star.png";

// function TestimonialsSlider(){
//   const responsive = {
//     superLargeDesktop: {
      
//       breakpoint: { max: 4000, min: 3000 },
//       items: 2,
//       slidesToSlide: 1
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1000 },
//       items: 1.5,
//       slidesToSlide: 1
//     },
//     desktop1: {
//       breakpoint: { max: 1000, min: 768 },
//       items: 1.5,
//       slidesToSlide: 1
//     },
//     tablet: {
//       breakpoint: { max: 768, min: 500 },
//       items: 2,
//       slidesToSlide: 1
//     },
//     mobile: {
//       breakpoint: { max: 500, min: 0 },
//       items: 1,
//       slidesToSlide: 1
//     }
//   };

//   return(
    
//     <div className='over'>
//       <Carousel 
//       autoPlay={true} 
//       autoPlaySpeed={4500}  
//       showDots={false} 
//       infinite={true}
//       pauseOnHover={true}
//       responsive={responsive}>
       
//             <div>
//              <Testimonials  
//              name ="Mr. & Mrs. Mehta" 
//              star={star5} 
//              text="I was impressed by the variety of job listings on Job Bridge India. Whether you're a recent graduate or an experienced professional, there's something for everyone. Highly recommend!"  />
//             </div>
//             <div>
//             <Testimonials  
//              name ="M/s. Kudale Agro Foods" 
//              star={star5} 
//              text="Job Bridge India helped me navigate the job market with confidence. Their comprehensive job descriptions and company profiles gave me the insights I needed to make informed decisions about my career."  />
//             </div>
//             <div>
//             <Testimonials  
//              name ="Mr. Jat" 
//              star={star4} 
//              text="Thanks to Job Bridge India, I landed my dream job! The platform's intuitive design and seamless application process made all the difference. I couldn't be happier with the outcome."  />
//             </div>
//             <div>
//             <Testimonials  
//              name ="Mr. Lakhotia" 
//              star={star4} 
//              text="Job Bridge India understands the importance of skills development. Their resources section offers valuable insights and tips for professional growth, making it more than just a job search platform."  />
//             </div>
//             <div>
//             <Testimonials  
//              name ="Mr. Shaikh " 
//              star={star4} 
//              text="As a freelancer, finding consistent work can be challenging, but Job Bridge India changed the game for me. Their freelance job section is unparalleled, providing me with a steady stream of opportunities."  />
//             </div>
//             <div>
//             <Testimonials  
//              name ="Mr. More" 
//              star={star5} 
//              text="I appreciated Job Bridge India's commitment to diversity and inclusion. They feature a wide range of companies and prioritize showcasing employers who value equality and equity in the workplace." />
//             </div>
//             <div>
//             <Testimonials  
//              name ="Mr. Gohil" 
//              star={star5} 
//              text="Job Bridge India's support team was exceptional. Whenever I had a question or encountered an issue, they were quick to respond and provided helpful guidance every step of the way." />
//             </div>
       
//       </Carousel>

//     </div>

//   );
// };
// export default TestimonialsSlider;

// import React, { useEffect } from 'react';

// const TestimonialsSlider = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://static.elfsight.com/platform/platform.js';
//     script.setAttribute('data-use-service-core', '');
//     script.defer = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div
//       className="elfsight-app-a7c355e2-201a-4c53-abe0-a1585ebd0b86"
//       data-elfsight-app-lazy
//     ></div>
//   );
// };

// export default TestimonialsSlider;


import React, { useEffect } from 'react';

const TestimonialsSlider = () => {
  useEffect(() => {
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.setAttribute('data-use-service-core', '');
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Script load error'));
        document.body.appendChild(script);
      });
    };

    const initializeScript = async () => {
      try {
        await loadScript();
        console.log('Elfsight script loaded successfully');
      } catch (error) {
        console.error('Error loading Elfsight script:', error);
      }
    };

    initializeScript();

    return () => {
      const scripts = document.querySelectorAll('script[src="https://static.elfsight.com/platform/platform.js"]');
      scripts.forEach(script => document.body.removeChild(script));
    };
  }, []);

  return (
    <div
      className="elfsight-app-a7c355e2-201a-4c53-abe0-a1585ebd0b86"
      data-elfsight-app-lazy
    ></div>
  );
};

export default TestimonialsSlider;



