import React, { useState } from 'react';
import Navbar from "../NAVBAR/Navbar";
import classes from "./signup.module.css";
import img from "./Image/login.jpg";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const [signUpForm, setSignUpForm] = useState({ fullName: '', email: '', mobile: '', password: '', confirmPassword: '' });
    const [message, setMessage] = useState('');

    const navigate=useNavigate()

    const handleSignUpChange = (e) => {
        const { name, value } = e.target;
        setSignUpForm({ ...signUpForm, [name]: value });
    };

    const handleSignUpSubmit = async (e) => {
        e.preventDefault();

        // console.log(signUpForm," formValue")

        try {
            const resp = await axios.post("https://jobbridgeindia.com/signup2", { signUpForm })

            console.log(resp)

            if(resp.status==200){
                alert("account success")
                navigate("/")
            }
            else if(resp.status==500){
                alert("error")
            }
           



        } catch (err) {
            console.log(err)
            alert('account already exist')
        }


        
    };

    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <div className={classes.card}>
                    <div className={classes.left}>
                        <img src={img} alt="jobridge" />
                    </div>
                    <div className={classes.right}>
                        <center><h1><span className={classes.s1}>SIGNUP</span></h1></center>
                        
                        {message && <p className={classes.message}>{message}</p>}
                        <form onSubmit={handleSignUpSubmit}>
                            <input
                                type="text"
                                name="fullName"
                                value={signUpForm.fullName}
                                onChange={handleSignUpChange}
                                placeholder="Enter Your Full Name"
                                className={classes.input}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                value={signUpForm.email}
                                onChange={handleSignUpChange}
                                placeholder="Enter Your Email"
                                className={classes.input}
                                required
                            />
                            <input
                                type="tel"
                                name="mobile"
                                value={signUpForm.mobile}
                                onChange={handleSignUpChange}
                                placeholder="Enter Your Mobile no."
                                className={classes.input}
                                required
                            />
                            <div className={classes.passwordInput}>
                                <input
                                    type="password"
                                    name="password"
                                    value={signUpForm.password}
                                    onChange={handleSignUpChange}
                                    placeholder="Enter Your Password"
                                    className={classes.input}
                                    required
                                />
                            </div>
                            <div className={classes.passwordInput}>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={signUpForm.confirmPassword}
                                    onChange={handleSignUpChange}
                                    placeholder="Re-Enter Your Password"
                                    className={classes.input}
                                    required
                                />
                            </div>
                            <button type="submit" className={classes.button}>
                                Sign Up
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;




