import React from 'react'
import { Link } from 'react-router-dom'
import classes from './Footer.module.css'
import logo1 from './Image/LOGO1.png'

import location from './Image/location2.png'
import phone from './Image/phone2.png'
import mail from './Image/mail2.png'

import facebook from './Image/facebook.png'
import instagram from './Image/instagram.png'
import li from './Image/li.jpg'

const Footer = () => {
    return (
        <div className={classes.mainHold}>
            <div className={classes.hold}>
                <div className={classes.frist}>
                    <div className={classes.hold1}>
                        <img className={classes.imgfooter} src={logo1}></img>
                        <p className={classes.footer1}>Job Bridge India Connecting recruiters with qualified candidates effortlessly.</p>

                    </div>

                    <div className={classes.socialIconHold}>
                        <a target="_blank" href='https://www.facebook.com/profile.php?id=61556532556824'><img src={facebook} className={classes.socialIcon}></img></a>
                        <a target="_blank" href='https://www.instagram.com/jobbridgeindia/'><img src={instagram} className={classes.socialIcon}></img></a>
                        <a target="_blank" href='https://linkedin.com/in/job-bridge-india-1635962b7'><img src={li} className={classes.socialIcon}></img></a>
                    </div>
                </div>

                <div className={classes.hold1}>
                    <p className={classes.footHd}>Quick Links</p>
                    <Link to='/' className={classes.footTxt}><p className={classes.footTxt}>Home</p></Link>
                    <Link to='/about' className={classes.footTxt}><p className={classes.footTxt}>About Us</p></Link>
                    <Link to='/contact' className={classes.footTxt}><p className={classes.footTxt}>Contact Us</p></Link>
                </div>

                <div className={classes.hold1}>
                    <div className={classes.adj}>
                        <p className={classes.footHd}>Company</p>
                        <Link to='/terms' className={classes.footTxt}><p className={classes.footTxt}>Terms & Conditions</p></Link>
                        <Link to='/talentmanagement' className={classes.footTxt}><p className={classes.footTxt}>Talent Management</p></Link>
                        <Link to='/HiringProcess' className={classes.footTxt}><p className={classes.footTxt}>Hiring Process</p></Link>
                        {/*<Link to='/contact' className={classes.footTxt}><p className={classes.footTxt}>Contact Us</p></Link> */}
                    </div>

                </div>

                <div className={classes.hold1}>
                    <div className={classes.adj1}>
                        <p className={classes.footHd}>Have A Question ?</p>
                        <div className={classes.divHold}>
                            <img src={location} className={classes.icon} alt="location icon" />
                            <a href="https://www.google.com/maps/search/?api=1&query=16th+Vasantika+Apartment+Opp.+Yena+Bungalow,+Jijau+Masaheb+Marg,+near.+Abhyudaya+Bank+Kothrud,+Pune,+Maharashtra+411038"
                                target="_blank"
                                className={classes.addressLink}>
                                16, Vasantika Apartment, Near Abhyudaya Bank, Paud Road, Kothrud, Pune, Maharashtra 411038


                            </a>
                        </div>

                        <div className={classes.divHold}>
                            <img src={phone} className={classes.icon} alt="phone icon" />

                            <a href="tel:+918484890029" className={classes.phoneLink}>
                                +91 8484890029
                            </a>
                        </div>

                        <div className={classes.divHold}>
                            <img src={mail} className={classes.icon} alt="mail icon" />
                            <div className={classes.emailContainer}>
                                <a href="mailto:info@jobbridgeindia.com" className={classes.mailLink}>
                                    info@jobbridgeindia.com
                                </a>
                                {/* <a href="mailto:support@jobbridgeindia.com" className={classes.mailLink}>
                                    support@jobbridgeindia.com
                                </a>
                                <a href="mailto:hrdocs@jobbridgeindia.com" className={classes.mailLink}>
                                    hrdocs@jobbridgeindia.com
                                </a> */}
                            </div>
                        </div>
                    </div>

                </div>

                <div className={classes.hold1}>
                    <div className={classes.adj2}>
                        <p className={classes.footHd}>Location</p>
                        <div className={classes.mapContainer2}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3157.7972347891273!2d73.822499!3d18.50878!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf5b0e84758f%3A0x778d8c8fc19af617!2sRaeesRoyal%20Private%20Limited!5e1!3m2!1sen!2sin!4v1720411891065!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                    </div>

                </div>


            </div>
            <hr className={classes.hr}></hr>
            <p className={classes.footBtm}>Copyright © 2024 Job Bridge India | Powered By Exads IT Solutions</p>
        </div>
    )
}

export default Footer;