


// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import axios from "axios";
// import Navbar from "../NAVBAR/Navbar";
// import classes from "./UserProfile.module.css";

// const UserProfile = () => {
//     const [user, setUser] = useState({
//         name: '',
//         email: '',
//         bio: '',
//         profilePic: null,
//         headline: '',
//         dateOfBirth: '',
//         phoneNumber: '',
//         state: '',
//         city: ''
//     });

//     const navigate = useNavigate();
//     const fileInputRef = useRef(null);

//     useEffect(() => {
//         const userEmail = Cookies.get('loginSuccess2');
//         const userName = Cookies.get('loginSuccess');

//         if (!userEmail) {
//             navigate("/login");
//         } else {
//             fetchUserData(userEmail);
//             setUser(prevUser => ({
//                 ...prevUser,
//                 email: userEmail,
//                 name: userName || "User"
//             }));
//         }
//     }, [navigate]);

//     const fetchUserData = async (email) => {
//         try {
//             const response = await axios.get(`http://localhost:8800/getUserProfile?email=${email}`);
//             if (response.data) {
//                 const userData = response.data;

                
//                 const dateOfBirth = userData.dateOfBirth ? userData.dateOfBirth.slice(0, 10) : '';

//                 setUser({
//                     ...userData,
//                     profilePic: userData.profilePic ? `http://localhost:8800/${userData.profilePic}` : null,
//                     dateOfBirth: dateOfBirth
//                 });
//             }
//         } catch (error) {
//             console.error("Error fetching user profile:", error);
//         }
//     };

//     const handleImageUpload = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             setUser(prevUser => ({
//                 ...prevUser,
//                 profilePic: file
//             }));
//         }
//     };

//     const handleProfileClick = () => {
//         fileInputRef.current.click();
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setUser(prevUser => ({
//             ...prevUser,
//             [name]: value
//         }));
//     };

//     const handleSave = async () => {
//         try {
//             const formData = new FormData();
//             formData.append('email', user.email);
//             formData.append('name', user.name);
//             formData.append('bio', user.bio);
//             formData.append('headline', user.headline);
//             formData.append('dateOfBirth', user.dateOfBirth);
//             formData.append('phoneNumber', user.phoneNumber);
//             formData.append('state', user.state);
//             formData.append('city', user.city);
//             if (user.profilePic && typeof user.profilePic !== 'string') {
//                 formData.append('profilePic', user.profilePic);
//             }

//             const response = await axios.post('http://localhost:8800/saveUserProfile', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             });

//             if (response.status === 200) {
//                 alert('User Profile updated successfully');
//                 fetchUserData(user.email); 
//             }
//         } catch (error) {
//             console.error("Error updating user profile:", error);
//             alert('Error updating user profile: ' + (error.response?.data?.error || error.message));
//         }
//     };

//     return (
//         <>
//             <Navbar />
//             <div className={classes.profileContainer}>
//                 <div className={classes.profileCard}>
//                     <div className={classes.profileImageContainer} onClick={handleProfileClick}>
//                         {user.profilePic ? (
//                             <img
//                                 src={typeof user.profilePic === 'string' ? user.profilePic : URL.createObjectURL(user.profilePic)}
//                                 alt="Profile"
//                                 className={classes.profileImage}
//                             />
//                         ) : (
//                             <div className={classes.defaultProfilePic}>
//                                 <span className={classes.defaultProfileText}>+</span>
//                             </div>
//                         )}
//                     </div>
//                     <input
//                         type="file"
//                         ref={fileInputRef}
//                         style={{ display: "none" }}
//                         accept="image/*"
//                         onChange={handleImageUpload}
//                     />

//                     <h1 className={classes.welcome}>Welcome, {user.name}</h1>
//                     <p className={classes.email}>Your registered email: {user.email}</p>

//                     <div className={classes.inputGroup}>
//                         <label htmlFor="headline">Headline:</label>
//                         <input
//                             type="text"
//                             id="headline"
//                             name="headline"
//                             value={user.headline}
//                             onChange={handleInputChange}
//                             className={classes.input}
//                             placeholder="Enter your headline..."
//                         />
//                     </div>

//                     <div className={classes.inputGroup}>
//                         <label htmlFor="dateOfBirth">Date of Birth:</label>
//                         <input
//                             type="date"
//                             id="dateOfBirth"
//                             name="dateOfBirth"
//                             value={user.dateOfBirth}
//                             onChange={handleInputChange}
//                             className={classes.input}
//                         />
//                     </div>

//                     <div className={classes.inputGroup}>
//                         <label htmlFor="phoneNumber">Phone Number:</label>
//                         <input
//                             type="text"
//                             id="phoneNumber"
//                             name="phoneNumber"
//                             value={user.phoneNumber}
//                             onChange={handleInputChange}
//                             className={classes.input}
//                             placeholder="Enter your phone number..."
//                         />
//                     </div>

//                     <div className={classes.inputGroup}>
//                         <label htmlFor="state">State:</label>
//                         <input
//                             type="text"
//                             id="state"
//                             name="state"
//                             value={user.state}
//                             onChange={handleInputChange}
//                             className={classes.input}
//                             placeholder="Enter your state..."
//                         />
//                     </div>

//                     <div className={classes.inputGroup}>
//                         <label htmlFor="city">City:</label>
//                         <input
//                             type="text"
//                             id="city"
//                             name="city"
//                             value={user.city}
//                             onChange={handleInputChange}
//                             className={classes.input}
//                             placeholder="Enter your city..."
//                         />
//                     </div>

//                     <div className={classes.inputGroup}>
//                         <label htmlFor="bio">Bio:</label>
//                         <textarea
//                             id="bio"
//                             name="bio"
//                             value={user.bio}
//                             onChange={handleInputChange}
//                             className={classes.textarea}
//                             placeholder="Tell us about yourself..."
//                         />
//                     </div>

//                     <button onClick={handleSave} className={classes.saveButton}>
//                         Save Changes
//                     </button>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default UserProfile;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import Navbar from "../NAVBAR/Navbar";
import classes from "./UserProfile.module.css";

const statesOfIndia = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
];

const UserProfile = () => {
    const [user, setUser] = useState({
        name: '',
        email: '',
        bio: '',
        profilePic: null,
        headline: '',
        dateOfBirth: '',
        phoneNumber: '',
        state: '',
        city: ''
    });
    
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    useEffect(() => {
        const userEmail = Cookies.get('loginSuccess2');
        const userName = Cookies.get('loginSuccess');

        if (!userEmail) {
            navigate("/login");
        } else {
            fetchUserData(userEmail);
            setUser(prevUser => ({
                ...prevUser,
                email: userEmail,
                name: userName || "User"
            }));
        }
    }, [navigate]);

    const fetchUserData = async (email) => {
        try {
            const response = await axios.get(`https://jobbridgeindia.com/getUserProfile?email=${email}`);
            if (response.data) {
                const userData = response.data;
                console.log(response.data)
                const dateOfBirth = userData.dateOfBirth ? userData.dateOfBirth.slice(0, 10) : '';

                setUser({
                    ...userData,
                    profilePic: userData.profilePic ? `https://jobbridgeindia.com/uploads/${userData.profilePic}` : null,
                    dateOfBirth: dateOfBirth
                });
            }
        } catch (error) {
            console.error("Error fetching user profile:", error);
            alert('Error fetching user profile: ' + (error.response?.data?.error || error.message));
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUser(prevUser => ({
                ...prevUser,
                profilePic: file
            }));
        }
    };

    const handleProfileClick = () => {
        fileInputRef.current.click();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser(prevUser => ({
            ...prevUser,
            [name]: value
        }));
    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('email', user.email);
            formData.append('name', user.name);
            formData.append('bio', user.bio);
            formData.append('headline', user.headline);
            formData.append('dateOfBirth', user.dateOfBirth);
            formData.append('phoneNumber', user.phoneNumber);
            formData.append('state', user.state);
            formData.append('city', user.city);
            if (user.profilePic && typeof user.profilePic !== 'string') {
                formData.append('profilePic', user.profilePic);
            }

            const response = await axios.post('https://jobbridgeindia.com/saveUserProfile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                setIsModalOpen(true); // Show modal on successful submission
            }
        } catch (error) {
            console.error("Error updating user profile:", error);
            alert('Error updating user profile: ' + (error.response?.data?.error || error.message));
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Navbar />
            <div className={classes.profileContainer}>
                <form onSubmit={handleSave} className={classes.profileCard}>
                    <div className={classes.profileImageContainer} onClick={handleProfileClick}>
                        {user.profilePic ? (
                            <img
                                src={typeof user.profilePic === 'string' ? user.profilePic : URL.createObjectURL(user.profilePic)}
                                alt="Profile"
                                className={classes.profileImage}
                            />
                        ) : (
                            <div className={classes.defaultProfilePic}>
                                <span className={classes.defaultProfileText}>+</span>
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageUpload}
                    />

                    <h1 className={classes.welcome}>Welcome, {user.name}</h1>
                    <p className={classes.email}>Your registered email: {user.email}</p>

                    <div className={classes.inputGroup}>
                        <label htmlFor="headline">Headline:</label>
                        <input
                            type="text"
                            id="headline"
                            name="headline"
                            value={user.headline}
                            onChange={handleInputChange}
                            className={classes.input}
                            placeholder="Enter your headline..."
                        />
                    </div>

                    <div className={classes.inputGroup}>
                        <label htmlFor="dateOfBirth">Date of Birth:</label>
                        <input
                            type="date"
                            id="dateOfBirth"
                            name="dateOfBirth"
                            value={user.dateOfBirth}
                            onChange={handleInputChange}
                            className={classes.input}
                        />
                    </div>

                    <div className={classes.inputGroup}>
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={user.phoneNumber}
                            onChange={handleInputChange}
                            className={classes.input}
                            placeholder="Enter your phone number..."
                        />
                    </div>

                    <div className={classes.inputGroup}>
                        <label htmlFor="state">State:</label>
                        <select
                            id="state"
                            name="state"
                            value={user.state}
                            onChange={handleInputChange}
                            className={classes.input}
                        >
                            <option className={classes.op1} value="">Select your state...</option>
                            {statesOfIndia.map((state, index) => (
                                <option key={index} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={classes.inputGroup}>
                        <label htmlFor="city">City:</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            value={user.city}
                            onChange={handleInputChange}
                            className={classes.input}
                            placeholder="Enter your city..."
                        />
                    </div>

                    <div className={classes.inputGroup}>
                        <label htmlFor="bio">Bio:</label>
                        <textarea
                            id="bio"
                            name="bio"
                            value={user.bio}
                            onChange={handleInputChange}
                            className={classes.textarea}
                            placeholder="Tell us about yourself..."
                        />
                    </div>

                    <button type="submit" className={classes.saveButton}>
                        Submit
                    </button>
                </form>

                {isModalOpen && (
                    <div className={classes.modal}>
                        <div className={classes.modalContent}>
                            <h2>Profile Updated Successfully!</h2>
                            <p>Your profile information has been saved.</p>
                            <button onClick={closeModal} className={classes.closeButton}>
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserProfile;
