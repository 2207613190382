import React from 'react';
import classes from './Notification.module.css';

const Notification = ({ message, onClose }) => {
  return (
    <div className={classes.notification}>
      <p>{message}</p>
      <button onClick={onClose}>X</button>
    </div>
  );
};

export default Notification;
