
import { useEffect } from "react";
import classes from "./At.module.css"
import { Link } from 'react-router-dom';

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
           

            <div className={classes.mainconatiner}>
                <div className={classes.container}>
                    <div className={classes.description}>

                        <h2 className={classes.h}>Job Bridge India</h2>

                        <p className={classes.hh}>About Us ➢</p>

                        <p className={classes.hhh}>"Job Bridge India is an innovative online job consultancy platform that seamlessly connects recruiters with qualified candidates.
                            Through our website, recruiters can effortlessly post job vacancies, specifying the desired qualifications and criteria. Meanwhile, job seekers can easily navigate through our extensive database of job listings, applying for positions that match their qualifications and career aspirations.
                            Job Bridge India simplifies the hiring process for recruiters and enhances the job search experience for candidates."</p>
                    </div>
                </div>
            
                <div className={classes.second}>
                    <div className={classes.container1}>

                    </div>
                    <div className={classes.description1}>
                        <h1 className={classes.d1}>Welcome to <span className={classes.clr}>Job Bridge India</span></h1><br></br>
                        <p className={classes.d2}><span className={classes.clr2}>Job Bridge India</span> is an innovative job consultancy platform that bridges the gap between recruiters and job seekers. Job seekers can then easily apply for these positions, leveraging the platform's intuitive interface to match their skills and qualifications with the job requirements.</p><br></br><br></br>

                        
                        <button className={classes.newnine}>
                                <Link to="/contact" className={classes.btnLink}>Get in touch with us</Link>
                            </button>
                    </div>
                </div>
                <br></br>
                <div className={classes.third}>
                    <p className={classes.third1}>Job Bridge India</p><br></br>
                    <p className={classes.third2}>Vision</p>

                </div>
                <div className={classes.four}>
                    <div className={classes.container2}>
                        <img className={classes.img2} src="https://img.freepik.com/free-photo/goals-target-aspiration-perforated-paper-bullseye_53876-71202.jpg?t=st=1715770901~exp=1715774501~hmac=5052547731c8a532b35269eaac9ec6fec1fe121d6be7c46fe1e9d5a8a4f8440c&w=740" height="100%" width="90%"></img>

                    </div>
                    <div className={classes.description2}>
                        <p className={classes.font}>
                            Our vision at Job Bridge India is to revolutionize the recruitment process by providing a seamless and efficient platform that connects talented individuals with rewarding employment opportunities. We strive to be the go-to destination for both recruiters seeking top talent and job seekers pursuing their dream careers.
                        </p>


                    </div>

                </div>
                <div className={classes.five}>
                    <p className={classes.five1}>Job Bridge India</p><br></br>
                    <p className={classes.five2}>Mision</p>

                </div>
                <div className={classes.six}>
                    <div className={classes.container3}>
                        <img src="https://hungrybird.in/assets/images/pg/about/bg-06.jpg?id=19403265efed4c1f36e136962b85bffc" height="100%" width="90%"></img>

                    </div>
                    <div className={classes.description3}>
                        <p className={classes.font}>
                            At Job Bridge India, our mission is to empower recruiters to find the perfect candidates for their job vacancies while simultaneously assisting job seekers in finding fulfilling employment opportunities that align with their skills and aspirations. We are committed to leveraging technology to streamline the recruitment process and make it more accessible and transparent for all parties involved.
                        </p>


                    </div>

                </div>
                <div className={classes.seven}>
                    <p className={classes.seven1}>Job Bridge India</p><br></br>
                    <p className={classes.seven2}>Team</p>

                </div>
                <div className={classes.eight}>
                    <div className={classes.container4}>
                        <img className={classes.img5} src="https://img.freepik.com/free-photo/people-with-laptops-office_23-2147656717.jpg?t=st=1715770627~exp=1715774227~hmac=8cc56148b2d5f0e07218a4102a7270a8af58b02de80a0fd674d650a7f47c931f&w=740" height="100%" width="90%"></img>

                    </div>
                    <div className={classes.description4}>
                        <p className={classes.font}>
                            The Job Bridge India team consists of passionate professionals dedicated to reshaping the job consultancy landscape. Our team comprises experienced developers, designers, recruiters, and customer support specialists who work tirelessly to ensure the platform's success. With diverse expertise and a shared commitment to excellence, we strive to continuously enhance the Job Bridge India experience for both recruiters and job seekers alike.
                        </p>


                    </div>
                </div>
                <div className={classes.nine}>

                    <div className={classes.nine1}>
                        <div className={classes.nine2}>
                            <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
                            <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
                        </div>

                    </div>

                </div>


            </div>

        </>
    )
}
export default AboutPage;