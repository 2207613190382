import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import classes from './Applyform.module.css';
import Footer from '../FOOTER/Footer';
import Navbar from '../NAVBAR/Navbar';
import SuccessModal from './SuccessModal'; // Import the modal component

const Applyform = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        resume: null,
        state: '',
        city: '',
        postalCode: '',
        comments: '',
        education: '',
        specialization: '',
        experience: '',
        jobTitle: '',
        company: '',
        receiveMessages: false,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            resume: file,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                formDataToSend.append(key, formData[key]);
            }
        }

        try {
            const response = await axios.post('https://jobbridgeindia.com/submitApplication', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                setIsModalOpen(true); // Open modal on success
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('Error submitting application. Please try again.');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        navigate('/showmore'); // Redirect to /showmore after closing the modal
    };
    // Lists for dropdowns
    const states = [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", 
        "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", 
        "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", 
        "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", 
        "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry"
    ];

    const educationLevels = [
        "None", "High School Diploma", "Associate's Degree", "Bachelor's Degree", "Master's Degree", "Doctorate",
        "Professional Degree", "Postdoctoral Training"
    ];

    const specializations = [
        "Computer Science", "Electrical Engineering", "Mechanical Engineering", "Civil Engineering", 
        "Chemical Engineering", "Electronics and telecommunications engineering","Biotechnology", "Mathematics", "Physics", "Chemistry", 
        "Economics", "Business Administration", "Marketing", "Finance", "Human Resources", 
        "Education", "Law", "Medicine", "Psychology", "Sociology", "Political Science"
    ];


    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <form className={classes.form1} onSubmit={handleSubmit}>
                    <h2 className={classes.hh}>Application Form</h2>
                    <label className={classes.lbl}>Name:</label>
                    <input
                        className={classes.inp}
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />

                    <label className={classes.lbl}>Email Id:</label>
                    <input
                        className={classes.inp}
                        type="email"
                        name="email"
                        placeholder="Enter your mail id"
                        value={formData.email}
                        onChange={handleInputChange}
                    />

                    <label className={classes.lbl}>Contact no:</label>
                    <input
                        className={classes.inp}
                        type="tel"
                        name="contact"
                        placeholder="Enter your contact no."
                        value={formData.contact}
                        onChange={handleInputChange}
                    />

                    <label className={classes.lbl} htmlFor="resume">Upload Resume:</label>
                    <input
                        className={classes.inp}
                        type="file"
                        id="resume"
                        name="resume"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                    />

                    <label className={classes.lbl} htmlFor="states">States:</label>
                    <select
                        className={classes.slct}
                        name="state"
                        id="states"
                        value={formData.state}
                        onChange={handleInputChange}
                    >
                        <option value="">Select a state</option>
                        {states.map((state, index) => (
                            <option key={index} value={state}>{state}</option>
                        ))}
                    </select>

                    <label className={classes.lbl}>City:</label>
                    <input
                        className={classes.inp}
                        type="text"
                        name="city"
                        placeholder="Enter your city"
                        value={formData.city}
                        onChange={handleInputChange}
                    />

                    <label className={classes.lbl} htmlFor="postal_code">Postal Code:</label>
                    <input
                        className={classes.inp}
                        type="text"
                        id="postal_code"
                        name="postalCode"
                        placeholder="Enter your postal code"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                    />

                    <label className={classes.lbl}>Comments (optional):</label>
                    <textarea
                        className={classes.inp1}
                        name="comments"
                        placeholder="Enter your comments here"
                        value={formData.comments}
                        onChange={handleInputChange}
                    />

                    <div className={classes.radiobtn}>
                        <h4 className={classes.hh1}>Answer these questions from the employer</h4>
                    </div>

                    <label className={classes.lbl} htmlFor="education">What is the highest level of education you have completed?</label>
                    <select
                        className={classes.slct}
                        name="education"
                        id="education"
                        value={formData.education}
                        onChange={handleInputChange}
                    >
                        <option value="">Select an option</option>
                        {educationLevels.map((level, index) => (
                            <option key={index} value={level}>{level}</option>
                        ))}
                    </select>

                    <label className={classes.lbl} htmlFor="specialization">Specialization:</label>
                    <select
                        className={classes.slct}
                        name="specialization"
                        id="specialization"
                        value={formData.specialization}
                        onChange={handleInputChange}
                    >
                        <option value="">Select a specialization</option>
                        {specializations.map((specialization, index) => (
                            <option key={index} value={specialization}>{specialization}</option>
                        ))}
                    </select>

                    <label className={classes.lbl}>How many years of experience do you have?</label>
                    <input
                        className={classes.custominput1}
                        type="number"
                        name="experience"
                        value={formData.experience}
                        onChange={handleInputChange}
                    />

                    <h5 className={classes.hh2}>Enter a past job that shows relevant experience</h5>
                    <label className={classes.lbl}>Job title:</label>
                    <input
                        className={classes.inpex}
                        type="text"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleInputChange}
                    />
                    <label className={classes.lbl}>Company:</label>
                    <input
                        className={classes.inpex}
                        type="text"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                    />

                    <div className={classes.checkbox}>
                        <input
                            className={classes.inp}
                            type="checkbox"
                            id="receiveMessages"
                            name="receiveMessages"
                            checked={formData.receiveMessages}
                            onChange={handleInputChange}
                        />
                        <label className={classes.lbl1} htmlFor="receiveMessages">
                            <p>I am happy to receive text messages from Conference Technologies, Inc.</p>
                        </label>
                    </div>

                    <button type="submit" className={classes.submitBtn}>Submit</button>
                </form>
            </div>
            <Footer />
            <SuccessModal isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
};

export default Applyform;
