// import classes from "./JobCategary.module.css";
// // import img1 from "./Jobicon/accounting.png"
// import img1 from "./Jobicon/accounting1.gif"
// import img2 from "./Jobicon/strategy-development.png"
// import img3 from "./Jobicon/graphic-designer.png"
// import img4 from "./Jobicon/code.png"
// import img5 from "./Jobicon/resource.png"
// import img6 from "./Jobicon/system.png"
// import img7 from "./Jobicon/customer-care.png"
// import img8 from "./Jobicon/health-insurance.png"
// import img9 from "./Jobicon/assign.png"
// import { Link } from "react-router-dom"

// const JobCategary = () => {
//     return (
//         <div className={classes.container}>
//             <h1>Popular Job Categories</h1>
//             <div className={classes.categary_container}>
//                 <div className={classes.row}>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img1} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Accounting / Finance</p>
//                             <p className={classes.small}>(02 open positions)</p>
//                             {/* <Link to="/applyForm" className={classes.btnLink}>Contact Us</Link> */}
//                         </div>
//                     </div>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img2} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Marketing</p>
//                             <p className={classes.small}>(86 open positions)</p>
//                         </div>
//                     </div>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img3} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Design</p>
//                             <p className={classes.small}>(86 open positions)</p>
//                         </div>
//                     </div>
//                 </div>

//                 <div className={classes.row}>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img4} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Development</p>
//                             <p className={classes.small}>(06 open positions)</p>
//                         </div>
//                     </div>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img5} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Human Resource</p>
//                             <p className={classes.small}>(44 open positions)</p>
//                         </div>
//                     </div>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img6} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Automotive Jobs</p>
//                             <p className={classes.small}>(06 open positions)</p>
//                         </div>
//                     </div>
//                 </div>

//                 <div className={classes.row}>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img7} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Customer Service</p>
//                             <p className={classes.small}>(03 open positions)</p>
//                         </div>
//                     </div>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img8} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Health and Care</p>
//                             <p className={classes.small}>(13 open positions)</p>
//                         </div>
//                     </div>
//                     <div className={classes.card}>
//                         <div className={classes.left}>
//                             <img src={img9} alt="JobRidge" />
//                         </div>
//                         <div className={classes.right}>
//                             <p className={classes.bold}>Project Management</p>
//                             <p className={classes.small}>(01 open positions)</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };
// export default JobCategary;




import classes from "./JobCategary.module.css";
import img1 from "./Jobicon/accounting1.gif"
import img2 from "./Jobicon/strategy-development.png"
import img3 from "./Jobicon/graphic-designer.png"
import img4 from "./Jobicon/code.png"
import img5 from "./Jobicon/resource.png"
import img6 from "./Jobicon/system.png"
import img7 from "./Jobicon/customer-care.png"
import img8 from "./Jobicon/health-insurance.png"
import img9 from "./Jobicon/assign.png"
import { Link } from "react-router-dom"

const JobCategory = () => {
    const categories = [
        { img: img1, title: "Accounting / Finance", positions: 2 },
        { img: img2, title: "Marketing", positions: 86 },
        { img: img3, title: "Design", positions: 86 },
        { img: img4, title: "Development", positions: 6 },
        { img: img5, title: "Human Resource", positions: 44 },
        { img: img6, title: "Automotive Jobs", positions: 6 },
        { img: img7, title: "Customer Service", positions: 3 },
        { img: img8, title: "Health and Care", positions: 13 },
        { img: img9, title: "Project Management", positions: 1 },
    ];

    return (
        <div className={classes.container}>
            <h1>Popular Job Categories</h1>
            <div className={classes.categary_container}>
                {[0, 3, 6].map((startIndex) => (
                    <div key={startIndex} className={classes.row}>
                        {categories.slice(startIndex, startIndex + 3).map((category, index) => (
                            <div key={index} className={classes.card}>
                                <div className={classes.left}>
                                    <img src={category.img} alt={category.title} />
                                </div>
                                <div className={classes.right}>
                                    <p className={classes.bold}>{category.title}</p>
                                    <p className={classes.small}>({category.positions.toString().padStart(2, '0')} open positions)</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JobCategory;