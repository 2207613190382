import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Footer from '../../FOOTER/Footer';
import Navbar from '../../NAVBAR/Navbar';
import Notification from './Notification';
import classes from './Create.module.css';
import { useNavigate } from 'react-router-dom';

const Create = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [step, setStep] = useState(1);
    const [showNotification, setShowNotification] = useState(false);

    /* Form state variables */
    const [openposition, setOpenposition] = useState("");
    const [jobtitle, setJobtitle] = useState([]);
    const [jobtype, setJobtype] = useState([]);
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [salary, setSalary] = useState("");
    const [jobtime, setJobtime] = useState([]);
    const [jobcategary, setJobcategary] = useState(null);
    const [hiring, setHiring] = useState(null);
    const [description, setDiscription] = useState("");
    const [key1, setKey1] = useState("");
    const [key2, setKey2] = useState("");
    const [key3, setKey3] = useState("");
    const [key4, setKey4] = useState("");
    const [key5, setKey5] = useState("");
    const [skill1, setSkill1] = useState("");
    const [skill2, setSkill2] = useState("");
    const [skill3, setSkill3] = useState("");
    const [skill4, setSkill4] = useState("");
    const [skill5, setSkill5] = useState("");
    const [profile, setProfile] = useState(null);

    const navigate = useNavigate();

    /* Options Select */
    const optionJobTitle = [
        { value: 'Accounting / Finance', label: 'Accounting / Finance' },
        { value: 'Sales / Marketing', label: 'Sales / Marketing' },
        { value: 'Design', label: 'Design' },
        { value: 'Development', label: 'Development' },
        { value: 'Human Resource', label: 'Human Resource' },
        { value: 'Automotive Jobs', label: 'Automotive Jobs' },
        { value: 'Customer Service', label: 'Customer Service' },
        { value: 'Health and Care', label: 'Health and Care' },
        { value: 'Project Management', label: 'Project Management' },
        { value: 'Receptionist', label: 'Receptionist' },
        { value: 'Engineering', label: 'Engineering' },

    ];

    const optionJobType = [
        { value: 'Hybrid', label: 'Hybrid' },
        { value: 'Work From Home', label: 'Work From Home' },
        { value: 'Work From Office', label: 'Work From Office' }
    ];

    const optionJobState = [
        { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
        { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
        { value: 'Assam', label: 'Assam' },
        { value: 'Bihar', label: 'Bihar' },
        { value: 'Chhattisgarh', label: 'Chhattisgarh' },
        { value: 'Goa', label: 'Goa' },
        { value: 'Gujarat', label: 'Gujarat' },
        { value: 'Haryana', label: 'Haryana' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Karnataka', label: 'Karnataka' },
        { value: 'Kerala', label: 'Kerala' },
        { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Manipur', label: 'Manipur' },
        { value: 'Meghalaya', label: 'Meghalaya' },
        { value: 'Mizoram', label: 'Mizoram' },
        { value: 'Nagaland', label: 'Nagaland' },
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Punjab', label: 'Punjab' },
        { value: 'Rajasthan', label: 'Rajasthan' },
        { value: 'Sikkim', label: 'Sikkim' },
        { value: 'Tamil Nadu', label: 'Tamil Nadu' },
        { value: 'Telangana', label: 'Telangana' },
        { value: 'Tripura', label: 'Tripura' },
        { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
        { value: 'Uttarakhand', label: 'Uttarakhand' },
        { value: 'West Bengal', label: 'West Bengal' },
        { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
        { value: 'Chandigarh', label: 'Chandigarh' },
        { value: 'Dadra and Nagar Haveli', label: 'Dadra and Nagar Haveli' },
        { value: 'Daman and Diu', label: 'Daman and Diu' },
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Lakshadweep', label: 'Lakshadweep' },
        { value: 'Puducherry', label: 'Puducherry' },
    ];


    const optionJobTime = [
        { value: 'Full-Time', label: 'Full-Time' },
        { value: 'Part-Time', label: 'Part-Time' },
        { value: 'Temporary', label: 'Temporary' },
        { value: 'Freelancing', label: 'Freelancing' }
    ];

    const optionJobCategary = [
        { value: 'Private', label: 'Private' },

    ];

    const optionJobHiring = [
        { value: 'Immediate', label: 'Immediate' },
        { value: 'Week', label: 'Week' },
        { value: 'Month', label: 'Month' },
    ];

    /* Handlers */
    const backHnd = () => setStep(step - 1);
    const nextHnd = () => setStep(step + 1);

    const hnd_positionopen = (e) => setOpenposition(e.target.value);

    const hnd_job_title = (selectedOptions) => {
        setJobtitle(selectedOptions);
    };

    const hnd_job_type = (selectedOptions) => {
        setJobtype(selectedOptions);
    };

    const hnd_city = (e) => setCity(e.target.value);

    // const hnd_state = (e) => setState(e.target.value);
    const hnd_state = (selectedOption) => {
        setState(selectedOption ? selectedOption.value : '');
    };

    const hnd_salary = (e) => setSalary(e.target.value);

    const hnd_jobtime = (selectedOptions) => setJobtime(selectedOptions);

    const hnd_jobcategary = (selectedOptions) => setJobcategary(selectedOptions);

    const hnd_hiring = (selectedOptions) => setHiring(selectedOptions);

    const hnd_discription = (e) => setDiscription(e.target.value);

    const hnd_key1 = (e) => setKey1(e.target.value);

    const hnd_key2 = (e) => setKey2(e.target.value);

    const hnd_key3 = (e) => setKey3(e.target.value);

    const hnd_key4 = (e) => setKey4(e.target.value);

    const hnd_key5 = (e) => setKey5(e.target.value);

    const hnd_skill1 = (e) => setSkill1(e.target.value);

    const hnd_skill2 = (e) => setSkill2(e.target.value);

    const hnd_skill3 = (e) => setSkill3(e.target.value);

    const hnd_skill4 = (e) => setSkill4(e.target.value);

    const hnd_skill5 = (e) => setSkill5(e.target.value);

    const hnd_profile = (e) => setProfile(e.target.files[0]);

    // Submit action
    const handleSubmit = (e) => {
        e.preventDefault();

        const formdata = new FormData();
        formdata.append("POSITION_OPEN", openposition);
        formdata.append("JOB_TITLE", jobtitle.map((item) => item.value));
        formdata.append("JOB_TYPE", jobtype.map((item) => item.value));
        formdata.append("CITY", city);
        formdata.append("STATE", state);
        formdata.append("SALARY", salary);
        formdata.append("JOB_TIME", jobtime.map((item) => item.value));
        formdata.append("JOB_CATEGARY", jobcategary?.value);
        formdata.append("HIRING", hiring?.value);
        formdata.append("PROFILE", profile);
        formdata.append("DISCRIPTION", description);
        formdata.append("KEY1", key1);
        formdata.append("KEY2", key2);
        formdata.append("KEY3", key3);
        formdata.append("KEY4", key4);
        formdata.append("KEY5", key5);
        formdata.append("SKILL1", skill1);
        formdata.append("SKILL2", skill2);
        formdata.append("SKILL3", skill3);
        formdata.append("SKILL4", skill4);
        formdata.append("SKILL5", skill5);

        axios.post("https://jobbridgeindia.com/addJob", formdata)
            .then(res => {
                console.log(res);
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                    navigate("/dashboard");
                }, 3000); // Wait for 3 seconds before redirecting
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <center><h1 className={classes.ss}>Post a New Job Recruitment</h1></center>

                <div className={classes.subcontainer}>
                    <form className={classes.form_container} onSubmit={handleSubmit}>
                        {step === 1 && (
                            <div className={classes.step_div}>
                                <section className={classes.column}>
                                    <label>Position Open:</label>
                                    <input type="text" name='POSITION_OPEN' onChange={hnd_positionopen} value={openposition} />
                                </section>
                                <section className={classes.column}>
                                    <label>Job Title:</label>
                                    <Select
                                        isMulti
                                        options={optionJobTitle}
                                        name='JOB_TITLE'
                                        onChange={hnd_job_title}
                                        value={jobtitle}
                                    />
                                </section>
                                <section className={classes.column}>
                                    <label>Job Type:</label>
                                    <Select
                                        isMulti
                                        options={optionJobType}
                                        name='JOB_TYPE'
                                        onChange={hnd_job_type}
                                        value={jobtype}
                                    />
                                </section>
                                <section className={classes.column}>

                                    <label>State:</label>
                                    <Select
                                        options={optionJobState}
                                        name='STATE'
                                        onChange={hnd_state}
                                        value={optionJobState.find(option => option.value === state)}
                                    />
                                </section>

                                <section className={classes.column}>
                                    <label>City:</label>
                                    <input type="text" name='CITY' onChange={hnd_city} value={city} />
                                </section>

                            </div>
                        )}

                        {step === 2 && (
                            <div className={classes.step_div}>
                                <section className={classes.column}>
                                    <label>Salary:</label>
                                    <input type="text" name='SALARY' onChange={hnd_salary} value={salary} />
                                </section>
                                <section className={classes.column}>
                                    <label>Job Time:</label>
                                    <Select
                                        isMulti
                                        options={optionJobTime}
                                        name='JOB_TIME'
                                        onChange={hnd_jobtime}
                                        value={jobtime}
                                    />
                                </section>
                                <section className={classes.column}>
                                    <label>Job Category:</label>
                                    <Select
                                        options={optionJobCategary}
                                        name='JOB_CATEGARY'
                                        onChange={hnd_jobcategary}
                                        value={jobcategary}
                                    />
                                </section>
                                <section className={classes.column}>
                                    <label>Hiring:</label>
                                    <Select
                                        options={optionJobHiring}
                                        name='HIRING'
                                        onChange={hnd_hiring}
                                        value={hiring}
                                    />
                                </section>
                                <section className={classes.column}>
                                    <label>Job Description:</label>
                                    <textarea
                                        name='DISCRIPTION'
                                        rows={10}
                                        onChange={hnd_discription}
                                        value={description}
                                    ></textarea>
                                </section>
                            </div>
                        )}

                        {step === 3 && (
                            <div className={classes.step_div}>
                                <section className={classes.column}>
                                    <label>Key Responsibility 1:</label>
                                    <input type="text" name='KEY1' onChange={hnd_key1} value={key1} />
                                </section>
                                <section className={classes.column}>
                                    <label>Key Responsibility 2:</label>
                                    <input type="text" name='KEY2' onChange={hnd_key2} value={key2} />
                                </section>
                                <section className={classes.column}>
                                    <label>Key Responsibility 3:</label>
                                    <input type="text" name='KEY3' onChange={hnd_key3} value={key3} />
                                </section>
                                <section className={classes.column}>
                                    <label>Key Responsibility 4:</label>
                                    <input type="text" name='KEY4' onChange={hnd_key4} value={key4} />
                                </section>
                                <section className={classes.column}>
                                    <label>Key Responsibility 5:</label>
                                    <input type="text" name='KEY5' onChange={hnd_key5} value={key5} />
                                </section>
                            </div>
                        )}

                        {step === 4 && (
                            <div className={classes.step_div}>
                                <section className={classes.column}>
                                    <label>Skill 1:</label>
                                    <input type="text" name='SKILL1' onChange={hnd_skill1} value={skill1} />
                                </section>
                                <section className={classes.column}>
                                    <label>Skill 2:</label>
                                    <input type="text" name='SKILL2' onChange={hnd_skill2} value={skill2} />
                                </section>
                                <section className={classes.column}>
                                    <label>Skill 3:</label>
                                    <input type="text" name='SKILL3' onChange={hnd_skill3} value={skill3} />
                                </section>
                                <section className={classes.column}>
                                    <label>Skill 4:</label>
                                    <input type="text" name='SKILL4' onChange={hnd_skill4} value={skill4} />
                                </section>
                                <section className={classes.column}>
                                    <label>Skill 5:</label>
                                    <input type="text" name='SKILL5' onChange={hnd_skill5} value={skill5} />
                                </section>
                            </div>
                        )}

                        {step === 5 && (
                            <div className={classes.step_div}>
                                <section className={classes.column}>
                                    <label>Upload Profile:</label>
                                    <input className={classes.i1} type="file" name='PROFILE' onChange={hnd_profile} />
                                </section>
                                <section className={classes.column}>
                                    <button type="submit" className={classes.submit_btn}>Submit</button>
                                </section>
                            </div>
                        )}
                    </form>
                    <div className={classes.step_hold}>
                        {step > 1 && <button onClick={backHnd} className={classes.btnStep1}>Back</button>}
                        {step < 5 && <button onClick={nextHnd} className={classes.btnStep2}>Next</button>}
                    </div>
                </div>
            </div>
            {showNotification && (
                <Notification message="You have posted the job successfully!" onClose={() => setShowNotification(false)} />
            )}
            <Footer />
        </>
    );
};

export default Create;

