// import classes from "./Rate.module.css"

// const Rate = () => {
//     return (
//         <div className={classes.container}>
//             <div className={classes.column}>
//                 <p className={classes.bold}>4M+</p>
//                 <p>4M daily active users</p>
//             </div>
//             <div className={classes.column}>
//                 <p className={classes.bold}>12K+</p>
//                 <p>Over 12k active job positions</p>
//             </div>
//             <div className={classes.column}>
//                 <p className={classes.bold}>2M+</p>
//                 <p>2M stories shared</p>
//             </div>
//         </div>
//     );
// };
// export default Rate;

import React, { useRef, useState, useEffect } from 'react';
import classes from "./Rate.module.css";

const AnimatedCounter = ({ end, duration }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const increment = end / (duration / 16); // 60 FPS

        const timer = setInterval(() => {
            start += increment;
            if (start > end) {
                setCount(end);
                clearInterval(timer);
            } else {
                setCount(Math.floor(start));
            }
        }, 16);

        return () => clearInterval(timer);
    }, [end, duration]);

    return <span className={classes.counter}>{count}+</span>;
};

const Rate = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once visible
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className={classes.container}>
            <section className={classes.section} ref={sectionRef}>
                <div className={classes.grid}>
                    <div>
                        {isVisible && <AnimatedCounter end={2000} duration={2000} />}
                        <p>Daily active users</p>
                    </div>
                    <div>
                        {isVisible && <AnimatedCounter end={6000} duration={2000} />}
                        <p>Happy Clients</p>
                    </div>
                    <div>
                        {isVisible && <AnimatedCounter end={5000} duration={2000} />}
                        <p>Over Active Job Positions</p>
                    </div>
                    <div>
                        {isVisible && <AnimatedCounter end={3000} duration={2000} />}
                        <p>Real Professionals</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Rate;

