
import React from "react";
import classes from "./Recruitment.module.css";
import j2 from "./Images/job1.jpeg";
import { Link } from 'react-router-dom'
import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";


const StaffingSolutions = () => {
 
  return (
    <>
    <Navbar />
    <div className={classes.maincontainer}>
      
      <div className={classes.background_image}>
        <div className={classes.content_box}>
          <header className={classes.header}>
            <h1 className={classes.main_title}>Job Bridge India</h1>
            <h2 className={classes.subtitle}>
            Recruitment <span className={classes.colorful_text}>➢</span>{" "}
              <span className={classes.multi_color_text}>Staffing Solutions</span>
            </h2>
            <p className={classes.tagline}>to Drive Your Business Forward</p>
          </header>
          <section className={classes.services_section}>
            <p className={classes.services_description}>
            Our Recruitment Services: Staffing Solutions for Your Business are designed to provide comprehensive and efficient solutions to meet your organization's hiring needs. With a dedicated team of experienced recruiters, we specialize in sourcing, screening, and selecting qualified candidates who align with your company's culture and specific requirements. From temporary positions to permanent hires, we leverage our extensive network and streamlined process to deliver top-tier professionals who can contribute to your business's growth and success. By partnering with us, you can save valuable time and resources while ensuring a seamless integration of new employees into your team. Trust us to be your reliable partner in finding the right talent to drive your business forward.
            </p>
          </section>
        </div>
      </div>
      <div className={classes.staffing_links}>
      <Link to="/StaffingSolution" className={classes.point1}>• Staffing</Link>
      <Link to="/HiringProcess" className={classes.point1}>• Hiring</Link>
      <Link to="/ExecutiveSearch" className={classes.point1}>• Executive Search</Link>

        
      </div>
      <div className={classes.second}>
        <div className={classes.container1}>

        </div>
        <div className={classes.description1}>
          <h1 className={classes.hr1}>Recruitment - Services</h1>
          <p className={classes.hr2}>Comprehensive<span className={classes.clr2}>Staffing Solutions  </span> <span className={classes.clr3}>to Drive Your Business Forward</span></p>
        
          <p className={classes.hr3}>New job prospects have been made possible by the government's increased investment in infrastructure, e-commerce, programmes like "Made in India," and the growth of start-ups. Globalisation are the primary factors affecting the employment landscape.</p>
          
          
          <button className={classes.cB}>
                                <Link to="/contact" className={classes.cB}>Get touch with us</Link>
                            </button>
        </div>
      </div>
      
      <div className={classes.coloum}>
        <div className={classes.coloum1}>
          <h4 className={classes.ch}>Consumer Sector Staffing</h4>
          <p className={classes.ch1}>-Contractual or temporary staffing</p>
          <p className={classes.ch1}>-Payroll support services</p>
          <p className={classes.ch1}>-HR administrative solutions</p>
          <p className={classes.ch1}>-Consumer Goods and Durables / Home Appliances</p>
        </div>
        <div className={classes.coloum2}>
          <h4 className={classes.ch}>Industrial Sector Staffing</h4>
          <p className={classes.ch1}>-Contractual or temporary staffing</p>
          <p className={classes.ch1}>-Payroll support services</p>
          <p className={classes.ch1}>-HR administrative solutions</p>
          <p className={classes.ch1}>-Engineering / Procurement & Construction</p>
        </div>

        <div className={classes.coloum3}>
          <h4 className={classes.ch}>Retail & Ecommerce Sector Staffing</h4>
          <p className={classes.ch1}>-Contractual or temporary staffing</p>
          <p className={classes.ch1}>-Payroll support services</p>
          <p  className={classes.ch1}>-HR administrative solutions</p>
          <p className={classes.ch1}>-Retail and E - Commerce</p>
          
        </div>


      </div>

      <div className={classes.nine}>

        
          <div className={classes.nine2}>
            <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
            <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
          </div>

        

      </div>
      
    </div>
    
    <Footer />
    </>
  );
};

export default StaffingSolutions;
