import { useEffect } from 'react';
import React from 'react'
import Footer from "../FOOTER/Footer";
import Navbar from "../NAVBAR/Navbar"
import classes from "./Hrconsulting.module.css"
import { Link } from 'react-router-dom';

const Hrconculting = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Navbar />
            <div className={classes.mainconatiner}>
                <div className={classes.container}>
                    <div className={classes.description}>

                        <h2 className={classes.h}>Job Bridge India</h2>
                        
                        <p className={classes.hh}>HR CONSULTING ➢  <span className={classes.clr}>HR Strategy</span></p>
                        
                        <p className={classes.hhh}>Your trusted partner in driving organisational excellence through strategic Human Resources solutions.At Job Bridge India, we understand the dynamic nature of today's business landscape, and we are committed to helping you navigate the complexities of HR strategy to ensure your company's success.</p>
                    </div>
                </div>
                <div className={classes.frist}>
                    <div className={classes.points}>
                    <Link to="/hrStrategy" className={classes.point1}>•HR Strategy</Link>
      <Link to="/talentmanagement" className={classes.point1}>•Talent Management</Link>
      <Link to="/org" className={classes.point1}>•Organisational Development</Link>
      <Link to="/hrservicesdelivery" className={classes.point1}>•HR Service Delivery</Link>
                        

                    </div>

                </div>
                <div className={classes.second}>
                    <div className={classes.container1}>

                    </div>
                    <div className={classes.description1}>
                        <h1 className={classes.hr1}>HR Consulting - Services</h1>
                        <p className={classes.hr2}>Explore our tailored<span className={classes.clr2}>HR strategy</span><span className={classes.clr3}>services encompassing organisation</span></p>
                        
                        <p className={classes.hr3}>Crafting a powerful HR strategy is essential for organizational success. Our experts collaborate with you to develop and implement strategic HR plans aligned with your business objectives. From talent acquisition and retention to fostering a culture of innovation, our HR strategies drive sustainable growth and excellence.</p>
                    
                        {/* <button className={classes.cB}>
                                <Link to="/contact" className={classes.cB}>Contact Us</Link>
                            </button> */}
                    </div>
                </div>
                
                
                <div className={classes.third}>
                    <p className={classes.third1}>Organisational Structure Interventions/ Organisation Design Visioning</p>
                    
                    <p className={classes.third2}> Embark on a journey of organisational transformation with our organisational Structure Interventions and Organisation Design Visioning services. We assist in crafting and refining structures that optimise efficiency, promote collaboration, and adapt seamlessly to evolving business landscapes.</p>
                    <p className={classes.third3}>Strategy Development</p>
                    
                    <p className={classes.third4}>Unlock the full potential of your organisation through our strategic development services. We collaborate with your team to define clear objectives, identify growth opportunities, and formulate actionable plans that pave the way for sustainable success.</p>
                    <p className={classes.third3}>HR Planning</p>
                    <p className={classes.third4}>Strategic workforce planning is at the core of our HR Planning services. We help you align your human capital with business goals, ensuring that you have the right talent in the right place at the right time to drive innovation and productivity.</p>
                     <p className={classes.third3}>HR Audit Metrics and Analytics</p>
                     <p className={classes.third4}>Harness the power of data with our HR Audit Metrics and Analytics solutions. We conduct thorough assessments of your HR processes, providing valuable insights and metrics to enhance decision-making and optimise resource allocation.</p>
                    <p className={classes.third3}>Benchmarking</p>
                    <p className={classes.third4}>Stay ahead of industry trends and competition through our Benchmarking services. We analyze your HR practices against industry benchmarks, enabling you to identify areas for improvement and implement best-in-class strategies.</p>
                    <p className={classes.third3}>Employer Branding</p>
                    <p className={classes.third4}>Build a compelling employer brand that attracts and retains top talent. Our Employer Branding services help you create a distinctive identity, showcasing your organisation as an employer of choice in a competitive market.</p>
                    <p className={classes.third3}>HR Governance</p>
                    <p className={classes.third4}>Ensure ethical practices and compliance with our HR Governance solutions. We provide frameworks and strategies to enhance HR governance, fostering a culture of accountability, transparency, and fairness within your organisation.</p>
                    <p className={classes.third5}>At Job Bridge India, we understand that an effective HR strategy is the backbone of organisational success. Whether you are a startup looking to establish a solid foundation or an established enterprise seeking to optimize your HR functions, our comprehensive suite of services is tailored to meet your unique needs. Elevate your HR strategy with us and take the first step towards a future of sustainable growth and excellence.</p>
                </div>
                <div className={classes.nine}>
                
                        <div className={classes.nine1}>
                            <div className={classes.nine2}>
                                <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
                                <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
                            </div>
                            
                        </div>
            
                </div>

            </div>

            <Footer />
        </>

    )
}

export default Hrconculting