import { useEffect } from "react";
import Navbar from "../../NAVBAR/Navbar";
import Footer from "../../FOOTER/Footer";
import classes from "./Dashboard.module.css";
import { Link } from "react-router-dom";

const Dashboard = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <div className={classes.content}>
                    <h2 className={classes.welcome}>Welcome to</h2>
                    <h1 className={classes.title}>Job Bridge India</h1>
                    <div className={classes.subcontainer}>
                        <Link to='/create' className={classes.btn}>
                            <span className={classes.btnText}>Post a Job</span>
                            <span className={classes.btnIcon}>+</span>
                        </Link>
                        <Link to='/postedjobs' className={classes.btn}>
                            <span className={classes.btnText}>Posted Jobs</span>
                            <span className={classes.btnIcon}>→</span>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Dashboard;