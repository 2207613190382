import { useEffect } from 'react';
import React, { useState } from 'react';
import classes from './Contact.module.css'
import Navbar from '../NAVBAR/Navbar';
import Footer from '../FOOTER/Footer';
import { useNavigate } from 'react-router-dom';
import { Link, NavLink } from "react-router-dom"
import bell from './img/bell.png';
import info1 from './img/info1.png'
import mark from './img/mark.png'
const ContactForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    return (
        
        <>
            <Navbar />


           




            <div className={classes.main_layout}>


                <div className={classes.left_side}>
                    {/* <h1 className={classes.s5}>How can we help?</h1> */}
                    <div class={classes.contact_form}>
                        <h2>Contact Us</h2>
                        {/* <form action="https://api.web3forms.com/submit" method="POST"> */}
                        <form  action="https://api.web3forms.com/submit" method="POST">
                        <input type="hidden" name="access_key" value="4da37828-0c35-482a-a1d1-ed705c98a25c"></input>
                            <label for="name">Name:</label>
                            <input type="text" id="name" name="name" required />

                            <label for="mobileNumber">Mobile Number:</label>
                            <input type="tel" id="mobileNumber" name="mobileNumber" pattern="[0-9]{10}" required></input>

                            <label for="email">Email:</label>
                            <input type="email" id="email" name="email" required />

                            <label for="message">Message:</label>
                            <textarea id="message" name="message" required></textarea>
                            <div className={classes.consent}>
                                <p className={classes.p1}>I consent that my personal data is stored according to The Digital Personal Data Protection Act, 2024.</p>

                                <div className={classes.consent1}>
                                    <input className={classes.consent2} type="checkbox" name="terms_conditions" required />
                                    <p className={classes.consent3}>I do accept Job Bridge India <Link to="/terms" className={classes.terms_link}>terms and conditions</Link>.</p>

                                </div>


                            </div>

                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                <div className={classes.right_side}>

                    <div className={classes.contact_links}>
                        <h1 className={classes.s6}><span className={classes.s1}>Job</span><span className={classes.s2}> Bridge</span><span className={classes.s2}> India</span></h1>
                        <h1 className={classes.s7}>Contact Us:</h1>
                        <p className={classes.s8}>If you have questions or just a curiosity, please do not hesitate to contact us. Our team will get in touch with you as soon as possible.</p>
                        <h6 className={classes.s9}><a href="tel:+918484890029" className={classes.phone_link}>+918484890029</a></h6>
                        {/* <h6 className={classes.s9}><a href="mailto:info@jobbridgeindia.com" className={classes.mail_link}>Email: info@jobbridgeindia.com</a> | <a href="mailto:hrdocs@jobbridgeindia.com" className={classes.mail_link1}>hrdocs@jobbridgeindia.com</a> | <a href="mailto:support@jobbridgeindia.com" className={classes.mail_link1}>support@jobbridgeindia.com</a></h6> */}
                        
                        <div className={classes.emailContainer}>
                               <h6> <a href="mailto:info@jobbridgeindia.com" className={classes.mailLink}>
                                 info@jobbridgeindia.com
                                </a></h6>
                                 <h6><a href="mailto:support@jobbridgeindia.com" className={classes.mailLink}>
                                    support@jobbridgeindia.com
                                </a></h6>
                                <h6>
                                <a href="mailto:hrdocs@jobbridgeindia.com" className={classes.mailLink}>
                                    hrdocs@jobbridgeindia.com
                                </a>
                                </h6>
                                
                            </div>
                            
                    </div>



                </div>
            </div>
            <div className={classes.last}>
                <div className={classes.center}>

                    <h2 className={classes.last1}> <img className={classes.img1} src={bell}></img> Important Alert: Protect Yourself from Job Scams <img className={classes.img1} src={bell}></img></h2>
                    <h2 className={classes.last1}> <img className={classes.img1} src={mark}></img> Urgent Notice: Beware of Fraudulent Activity</h2>
                </div>
                <div className={classes.box}>
                    <h6 className={classes.last3}>Dear Visitors,</h6>



                    <h6 className={classes.last3}>It has come to our immediate attention that certain unauthorized individuals are deceitfully using our company's identity to exploit innocent job seekers. These fraudulent actors are misusing our name, logo, and address to promote deceptive work-from-home opportunities.</h6>

                    <h6 className={classes.last3}>Please be aware that our company does not engage in any work-from-home schemes, and any claims suggesting otherwise are entirely false. We are committed to upholding the highest standards of integrity and professionalism.</h6>

                    <h6 className={classes.last3}>To ensure your safety and protect the integrity of our brand, exercise caution and verify any communication that claims to be from us. Importantly, we want to emphasize that we do not request any form of payment from job seekers.</h6>

                    <h6 className={classes.last3}>For your convenience, our official communication channels are as follows:</h6>
                    <h6 className={classes.last3}>Call Us: <a href="tel:+91 8484890029" className={classes.t1}> +91 8484890029</a>
                        
                    </h6>
                    <h6 className={classes.last3}>Email Us: <a href="mailto:hrdocs@jobbridgeindia.com" className={classes.t1}>info@jobbridgeindia.com</a> | <a href="mailto:hrdocs@jobbridgeindia.com" className={classes.t1}>support@jobbridgeindia.com</a> |  <a href="mailto:hrdocs@jobbridgeindia.com" className={classes.t1}>hrdocs@jobbridgeindia.com</a>
                                                            
                                                           
                        

                    </h6>
                    {/* <h6 className={classes.last3}>Additional contacts listed on our CONTACT PAGE</h6> */}
                    <h6 className={classes.last3}>If you encounter any suspicious activity or have concerns, we urge you to promptly reach out to our official contact channels.</h6>

                    <h6 className={classes.last3}>We appreciate your vigilance and cooperation in helping us maintain a secure environment for all. Rest assured, we are actively addressing this fraudulent activity.</h6>

                    <h6 className={classes.last3}>Thank you for your understanding and cooperation.</h6>

                    <h6 className={classes.last3}>Best regards,</h6>

                    <h6 className={classes.last3}>Job Bridge India IT Consulting Services</h6>
                    <h6 className={classes.last3}>Business Development and Marketing Team</h6>
                </div>


            </div>



        </>

    );
};

export default ContactForm;




