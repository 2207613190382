import Navbar from "../../NAVBAR/Navbar"
import Footer from "../../FOOTER/Footer"
import classes from "./ShowMore.module.css"
import img1 from "../Jobicon/portfolio.png"
import img2 from "../Jobicon/location.png"
import img3 from "../Jobicon/clock.png"
import img4 from "../Jobicon/money.png"
import { useState, useEffect } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import { Link, useNavigate } from 'react-router-dom'



const JobDescription = () => {

    const [jobs, setJobs] = useState([]);
    const navigate = useNavigate()

    // Define the calculateTimeDifference function inside the Featured component
    const calculateTimeDifference = (timestamp) => {
        const currentTime = new Date();
        const targetTime = new Date(timestamp);
        const difference = currentTime - targetTime;
        const seconds = Math.floor(difference / 1000);

        if (seconds < 86400) {
            // Less than 1 day
            return `${Math.floor(seconds / 3600)} hours ago`;
        } else {
            // 1 day or more
            return `${Math.floor(seconds / 86400)} days ago`;
        }
    };

    const detailHandle = (ID) => {
        //console.log(ID)
        Cookies.set('D_ID', ID);
        //const URL = 'http://localhost:8800/Details'
        //window.open(URL,'blank')
        navigate('/Details')
    }

    useEffect(() => {
        const fetchAllJobs = async () => {
            try {
                const res = await axios.get("https://jobbridgeindia.com/feturedJob");
                console.log(res)
                setJobs(res.data)
            } catch (err) {
                console.log(err)
            }
        }
        fetchAllJobs();
    }, []);
    return (
        <>
            <Navbar />
            <div className={classes.subContainer}>
                {jobs && jobs.map((item, index) => (
                    // <div className={classes.card} key={index} onClick={()=>detailHandle(item.ID)}>
                    <div className={classes.card} key={index} >
                        <div className={classes.left}>
                            <img src={`https://jobbridgeindia.com/Uploads/` + item.JOB_ICON} alt="JOBRIDGE" />
                        </div>
                        <div className={classes.right}>
                            <p className={classes.bold}>{item.POSITION_OPEN}, {item.JOB_TITLE}</p>
                            <div className={classes.row}><img src={img1} /> <p>{item.JOB_TITLE}</p> <img src={img2} /> <p>{item.CITY}, {item.STATE}</p> <img src={img3} /><p>{calculateTimeDifference(item.TIME_INSERTION)}</p> <img src={img4} /><p>{item.SALARY}</p></div>
                            <div className={classes.row}><button className={classes.high1}>{item.JOB_TIME}</button> <button className={classes.high2}>{item.JOB_CATEGARY}</button> <button className={classes.high3}>{item.HIRING}</button> </div>
                            {/* <div><button className={classes.lnkbtn1}><Link to="/Details" className={classes.lnkbtn1}>Details</Link></button> </div> 
                        */}
                            <div><button onClick={() => { detailHandle(item.ID) }} className={classes.lnkbtn1}>Details</button> </div>

                        </div>

                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};
export default JobDescription;




