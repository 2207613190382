import classes from "./Advertise.module.css"
// import add from "./Images/add.jpg"
import { Link } from 'react-router-dom';
import add2 from "./Images/add2.png"


const Advertise = () => {
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                {/* <img src={add} alt="jobridge"/> */}
                <img src={add2} alt="jobridge"/>
            </div>
            <div className={classes.right}>
                <h1>Millions of Jobs. Find the one that suits you.</h1>
                <p className={classes.para}>Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 600,000 companies worldwide.</p>
                <p>- Bring to the table win-win survival</p>
                <p>- Capitalize on low hanging fruit to identify</p>
                <p>- But I must explain to you how all this</p>
                {/* <button className={classes.btn}>Contact Now</button> */}
                <button className={classes.btn}>
    <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
</button>
                
            </div>
        </div>
    );
};
export default Advertise;