import { useEffect } from "react";
import Featured from "../HOME/Featured";
import Hero from "../HOME/Hero";
import JobCategary from "../HOME/JobCategary";
import Navbar from "../NAVBAR/Navbar"
import TestimonialHead from "../HOME/TestimonialsHead"
import TestimonialSlider from "../HOME/TestimonialsSlider"
import Advertise from "../HOME/Advertise";
import Rate from "../HOME/Rate";
import Footer from "../FOOTER/Footer";
import Contact from "../CONTACT/Contact";
import Watsapp from "../WATSAPP/Watsapp";


import Footer3 from "../FOOTER/Footer3";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Navbar />
            <Hero />
            <JobCategary />
            <Featured />
            <TestimonialHead />
            <TestimonialSlider />
            <Advertise />
            <Rate />
            <Contact />
            <Watsapp />
            <Footer />
            {/* <Footer3 /> */}

        </>
    )
}
export default Home;