import { useEffect } from 'react';
import React from 'react'
import Footer from "../FOOTER/Footer";
import Navbar from "../NAVBAR/Navbar";
import classes from "./Hrservicedelivery.module.css"
import { Link } from 'react-router-dom';

const Hrservicedelivery = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Navbar />

            <div className={classes.maincontainer}>
                <div className={classes.container}>
                    <div className={classes.description}>

                        <h2 className={classes.h}>Job Bridge India</h2>
                       
                        <p className={classes.hh}>HR Consulting ➢  <span className={classes.clr}> HR Service Delivery</span></p>
                        
                        <p className={classes.hhh}>where precision meets people, and excellence defines every interaction. At Job Bridge India, we take pride in offering a comprehensive suite of HR Consulting Services to streamline and optimise your human resources processes. success.</p>
                    </div>
                </div>
                <div className={classes.frist}>
                    <div className={classes.points}>
                        <Link to="/hrStrategy" className={classes.point1}>•HR Strategy</Link>
                        <Link to="/talentmanagement" className={classes.point1}>•Talent Management</Link>
                        <Link to="/org" className={classes.point1}>•Organisational Development</Link>
                        <Link to="/hrservicesdelivery" className={classes.point1}>•HR Service Delivery</Link>


                    </div>

                </div>
                <div className={classes.second}>
                    <div className={classes.container1}>

                    </div>
                    <div className={classes.description1}>
                        <h1 className={classes.hr1}>HR Service Delivery - Services</h1>
                        <p className={classes.hr2}>Explore our tailored <span className={classes.clr2}>HR Service Delivery</span><span className={classes.clr3}>encompassing organisation</span></p>
                        
                        <p className={classes.hr3}>Streamline your HR service delivery model for maximum efficiency and employee satisfaction. Our experts help you implement best practices, leverage technology, and innovate your service delivery approach. Create a seamless and user-friendly HR service experience that not only meets but exceeds the expectations of your workforce.</p>
{/*                         
                        <button className={classes.cB}>
                            <Link to="/contact" className={classes.cB}>Get Touch with us</Link>
                        </button> */}
                    </div>
                </div>
                <br></br>
                <div className={classes.third}>
                    <p className={classes.third1}>Communication and Coordination</p>

                    <p className={classes.third2}>Establish seamless communication channels and coordination mechanisms to ensure information flows effortlessly across your organisation.</p>
                    <p className={classes.third3}>Policies Development</p>
                    <p className={classes.third4}>Craft clear, comprehensive, and compliant HR policies that align with your organisation's values, fostering a positive and transparent work environment.</p>
                    <p className={classes.third3}>Policies Implementation</p>
                    <p className={classes.third4}>Efficiently implement HR policies, ensuring a standardized and fair approach to workforce management.</p>
                    <p className={classes.third3}>Payroll</p>
                    <p className={classes.third4}>Experience error-free payroll processing that adheres to regulatory requirements, guaranteeing timely and accurate compensation for your workforce.</p>
                    <p className={classes.third3}>Benefits Administration</p>
                    <p className={classes.third4}>Optimize employee benefits programs, providing a competitive edge in talent attraction and retention.</p>
                    <p className={classes.third3}>Sourcing Talent</p>
                    <p className={classes.third4}>Utilize effective talent acquisition strategies to identify and attract top-notch professionals who align with your organisational goals.</p>
                    <p className={classes.third3}>Statutory Compliance</p>
                    <p className={classes.third4}>Navigate the complex landscape of employment laws and regulations effortlessly, ensuring your organisation remains compliant at all times.</p>
                    <p className={classes.third3}>Performance Appraisals Administration</p>
                    <p className={classes.third4}>Implement performance appraisal systems that drive employee development and align individual goals with organisational objectives.</p>
                    <p className={classes.third3}>Grievance Management</p>
                    <p className={classes.third4}>Establish fair and efficient grievance resolution mechanisms, fostering a positive workplace culture and employee satisfaction.</p>
                    <p className={classes.third3}>HR Business Partnering</p>
                    <p className={classes.third4}>Collaborate with our HR experts to strategically align human resources with your business goals, creating a true partnership for organisational success.</p>

                    <p className={classes.third5}>At Job Bridge India, we understand that effective HR Service Delivery is the backbone of a thriving organisation. Partner with us to transform your HR processes and elevate your workforce management to new heights.</p>
                </div>
                <br></br>
                <div className={classes.nine}>

                    <div className={classes.nine1}>
                        <div className={classes.nine2}>
                            <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
                            <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
                        </div>

                    </div>

                </div>

            </div>
            <Footer />
        </>
    )
}

export default Hrservicedelivery