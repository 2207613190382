import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./COMPONENTS/PAGES/Home";
import About from "./COMPONENTS/PAGES/About";
import Login from "./COMPONENTS/LOGIN/Login";
import Dashboard from "./COMPONENTS/ADMIN/DASHBOARD/Dashboard";
import Create from "./COMPONENTS/ADMIN/CREATE/Create";
import PostedJobs from "./COMPONENTS/ADMIN/CREATE/PostedJobs";
import ShowMore from "./COMPONENTS/HOME/JobDescription/ShowMore";
import JobDetails from "./COMPONENTS/HOME/JobDescription/JobDetails";
import Hrconculting from "./COMPONENTS/HR CONSULTING/Hrconculting";
import Talentmanagement from "./COMPONENTS/HR CONSULTING/Talentmanagement";
import Org from "./COMPONENTS/HR CONSULTING/Orgdvlp";
import HrServiceDelivery from "./COMPONENTS/HR CONSULTING/Hrservicedelivery";
import StaffingSolution from "./COMPONENTS/RECRUITMENT/StaffingSolutions";
import HiringProcess from "./COMPONENTS/RECRUITMENT/Hiringprocess";
import ExecutiveSearch from "./COMPONENTS/RECRUITMENT/ExecutiveSearch";
import Applyform from "./COMPONENTS/APPLY FORM/Applyform";
import EnquiryForm from "./COMPONENTS/CONTACT/EnquiryForm";

import Terms from "./COMPONENTS/PAGES/Terms";
import ContactForm from "./COMPONENTS/PAGES/Contact";
import Signup from "./COMPONENTS/LOGIN/Signup";
import UserProfile from './COMPONENTS/LOGIN/UserProfile';
// import ResetPassword from './COMPONENTS/LOGIN/ResetPassword';
import Notification from "./COMPONENTS/ADMIN/CREATE/Notification";
import SuccessModal from "./COMPONENTS/APPLY FORM/SuccessModal"




function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/UserProfile" element={<UserProfile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create" element={<Create />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/postedjobs" element={<PostedJobs />} />
          <Route path="/showmore" element={<ShowMore />} />
          <Route path="/Details" element={<JobDetails />} />
          <Route path="/hrStrategy" element={<Hrconculting />} />
          <Route path="/talentmanagement" element={<Talentmanagement />} />
          <Route path="/org" element={<Org />} />
          <Route path="/hrservicesdelivery" element={<HrServiceDelivery />} />
          <Route path="/StaffingSolution" element={<StaffingSolution />} />
          <Route path="/HiringProcess" element={<HiringProcess />} />
          <Route path="/ExecutiveSearch" element={<ExecutiveSearch />} />
          <Route path="/applyForm" element={<Applyform />} />
          <Route path="/enquiry" element={<EnquiryForm />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/SuccessModal" element={<SuccessModal />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
