import React from 'react';
import classes from './SuccessModal.module.css';

const SuccessModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={classes.modalOverlay}>
            <div className={classes.modalContent}>
                <h2 className={classes.modalTitle}>Application Submitted!</h2>
                <p className={classes.modalMessage}>Your application was successfully submitted.</p>
                <button onClick={onClose} className={classes.closeButton}>Close</button>
            </div>
        </div>
    );
};

export default SuccessModal;
