import { useEffect } from "react";
import React from "react";
import classes from "./Recruitment.module.css";
import j3 from "./Images/job1.jpeg";
import {Link} from 'react-router-dom'
import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";

const Hiringprocess = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, []);
  return (
    <>
    <Navbar />
    <div className={classes.maincontainer}>
    
      <div className={classes.background_image}>
        <div className={classes.content_box}>
          <header className={classes.header}>
            <h1 className={classes.main_title}>Job Bridge India</h1>
            <h2 className={classes.subtitle}>
              Recruitment <span className={classes.colorful_text}>➢</span>{" "}
              <span className={classes.multi_color_text}>Hiring Process</span>
            </h2>
            <p className={classes.tagline}>with Expert Support</p>
          </header>
          <section className={classes.services_section}>
            <p className={classes.services_description}>
              Our recruitment services specialize in simplifying and optimizing
              your hiring process. With a focus on delivering efficient and
              effective results, we offer comprehensive support to help you find
              the perfect candidates for your organization. From sourcing and
              screening to interviewing and selection, our team of experts
              utilizes proven strategies and a vast network to connect you with
              top talent. By partnering with us, you can streamline your hiring
              efforts, save valuable time and resources, and ensure a seamless
              integration of new hires into your team. Trust us to handle your
              hiring needs and find the right candidates to drive your business
              forward.
            </p>
          </section>
        </div>
      </div>
      <div className={classes.staffing_links}>
      <Link to="/StaffingSolution" className={classes.point1}>• Staffing</Link>
      <Link to="/HiringProcess" className={classes.point1}>• Hiring</Link>
      <Link to="/ExecutiveSearch" className={classes.point1}>• Executive Search</Link>
      
      </div>
      <div className={classes.second}>
        <div className={classes.container1}>

        </div>
        <div className={classes.description1}>
          <h1 className={classes.hr1}>Recruitment - Services</h1>
          <p className={classes.hr2}>Streamline Your <span className={classes.clr2}>Hiring Process</span>  <span className={classes.clr3}>with Professional Recruitment Services</span></p>
         
          <p className={classes.hr3}>Our track record demonstrates our ability to understand client talent requirements and provide clients with an excellent option for locating important people faster, while freeing up management bandwidth to focus on essential management operations.</p>
          
          <button className={classes.cB}>
                                <Link to="/contact" className={classes.cB}>Get touch with us</Link>
                            </button>
        </div>
      </div>
      
      <div className={classes.last}>
        <p className={classes.last1}>Overall, we offer these three different models to provide our clients with flexibility in how they choose to work with us. We have found that each model can be effective depending on the needs of the client and the specifics of the recruitment process.</p>
      </div>

      <div className={classes.nine}>

        <div className={classes.nine1}>
          <div className={classes.nine2}>
            <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
            <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
          </div>

        </div>

      </div>
      
      
    </div>
      <Footer />
     </>
  );
};

export default Hiringprocess;
