// import React, { useState, useEffect } from 'react';
// import { Link, NavLink } from 'react-router-dom';
// import LOGO1 from './IMAGE/LOGO1.png';
// import Cookies from 'js-cookie';
// import { useNavigate } from 'react-router-dom';
// import './Navbar.css';

// const Navbar = () => {
 

//   const LOGINSUCCESS = Cookies.get('loginSuccess');
//   const navigate = useNavigate();
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [userName, setUserName] = useState('');

//   useEffect(() => {
//     const storedUserName = Cookies.get('userName');
//     if (storedUserName) {
//       setUserName(storedUserName);
//     }
//   }, []);

//   const logBtnHnd = async () => {

//     Cookies.remove('loginSuccess');
//     Cookies.remove('D_ID');
//        Cookies.remove('userName');
//        window.location.reload()
//     // if (LOGINSUCCESS) {
//     //   Cookies.remove('loginSuccess');
//     //   Cookies.remove('D_ID');
//     //   Cookies.remove('userName');
//     //   setUserName('');
//     //   navigate('/');
//     // } else {
//     //   navigate('/login');
//     // }
//   };

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <div className='container'>
//       <div className='LOGO'>
//         <Link to='/'><img src={LOGO1} alt="JobRidge" /></Link>
//       </div>
//       <div className='mobile-menu'>
//         <div className={`nav__toggler ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
//           <div className="line1"></div>
//           <div className="line2"></div>
//           <div className="line3"></div>
//         </div>
//       </div>
//       <div className={`linkContainer ${isMenuOpen ? 'active' : ''}`}>
//         <li><NavLink activeClassName="active" className="navlnk" to="/">HOME</NavLink></li>
//         <li><NavLink activeClassName="active" className="navlnk" to="/about">ABOUT US</NavLink></li>
//         <li className="dropdown">
//           <button className="dropdownContent2">RECRUITMENTS <i className="fa-solid fa-angle-down"></i></button>
//           <div className="dropdown-content">
//             <Link to="/StaffingSolution">Staffing Solutions</Link>
//             <Link to="/HiringProcess">Hiring Process</Link>
//             <Link to="/ExecutiveSearch">Executive Search</Link>
//           </div>
//         </li>
//         <li className="dropdown">
//           <button className="dropdownContent2">HR CONSULTING <i className="fa-solid fa-angle-down"></i></button>
//           <div className="dropdown-content">
//             <Link to="/hrStrategy">HR Strategy</Link>
//             <Link to="/talentmanagement">Talent Management</Link>
//             <Link to="/org">ORG Development</Link>
//             <Link to="/hrservicesdelivery">HR Services</Link>
//           </div>
//         </li>
//         <li><NavLink activeClassName="active" className="navlnk" to="/contact">CONTACT US</NavLink></li>
//         {LOGINSUCCESS ? (
//           <div className="loginDropdown">
//             <p className="loginDropdownContent">HELLO {userName || LOGINSUCCESS} <i className="username"></i></p>
//             <div className="logindropdown-content">
//               <Link to='/dashboard'>My Dashboard</Link>
//               <button className="login2" onClick={logBtnHnd}>Logout</button>
//               <Link to='/UserProfile'>PROFILE</Link>
              
//             </div>
//           </div>
//         ) : (
//           <li><button className='login'><Link to="/login" className="lnk">Login</Link></button></li>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Navbar;


import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LOGO1 from './IMAGE/LOGO1.png';
import Cookies from 'js-cookie';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const storedUserName = Cookies.get('loginSuccess');
    const storedEmail = Cookies.get('loginSuccess2'); // Make sure to set 'userEmail' in cookies upon login
    
    console.log(storedEmail);
    if (storedUserName) {
      setUserName(storedUserName);
    }
    
    if (storedEmail === 'jobbridgeindia29@gmail.com') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  const logBtnHnd = () => {
    Cookies.remove('loginSuccess');
    Cookies.remove('D_ID');
    Cookies.remove('userName');
    Cookies.remove('userEmail'); // Remove the email as well
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='container'>
      <div className='LOGO'>
        <Link to='/'><img src={LOGO1} alt="JobRidge" /></Link>
      </div>
      <div className='mobile-menu'>
        <div className={`nav__toggler ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
      <div className={`linkContainer ${isMenuOpen ? 'active' : ''}`}>
        <li><NavLink activeClassName="active" className="navlnk" to="/">HOME</NavLink></li>
        <li><NavLink activeClassName="active" className="navlnk" to="/about">ABOUT US</NavLink></li>
        <li className="dropdown">
          <button className="dropdownContent2">RECRUITMENTS <i className="fa-solid fa-angle-down"></i></button>
          <div className="dropdown-content">
            <Link to="/StaffingSolution">Staffing Solutions</Link>
            <Link to="/HiringProcess">Hiring Process</Link>
            <Link to="/ExecutiveSearch">Executive Search</Link>
          </div>
        </li>
        <li className="dropdown">
          <button className="dropdownContent2">HR CONSULTING <i className="fa-solid fa-angle-down"></i></button>
          <div className="dropdown-content">
            <Link to="/hrStrategy">HR Strategy</Link>
            <Link to="/talentmanagement">Talent Management</Link>
            <Link to="/org">ORG Development</Link>
            <Link to="/hrservicesdelivery">HR Services</Link>
          </div>
        </li>
        <li><NavLink activeClassName="active" className="navlnk" to="/contact">CONTACT US</NavLink></li>
        {Cookies.get('loginSuccess') ? (
          <div className="loginDropdown">
            <p className="loginDropdownContent">HELLO {userName || Cookies.get('loginSuccess')} <i className="username"></i></p>
            <div className="logindropdown-content">
              {isAdmin ? (
                <Link to='/dashboard'>My Dashboard</Link>
                
              ) : (
                <Link to='/UserProfile'>PROFILE</Link>
              )}
              <button className="login2" onClick={logBtnHnd}>Logout</button>
            </div>
          </div>
        ) : (
          <li><button className='login'><Link to="/login" className="lnk">Login</Link></button></li>
        )}
      </div>
    </div>
  );
};

export default Navbar;
