import { useEffect } from 'react';
import React from 'react'
import Footer from "../FOOTER/Footer";
import Navbar from "../NAVBAR/Navbar";
import classes from "./Talentmanagement.module.css"
import { Link } from 'react-router-dom';

const Talentmanagement = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Navbar />

            <div className={classes.maincontainer}>
                <div className={classes.container}>
                    <div className={classes.description}>

                        <h2 className={classes.h}>Job Bridge India</h2>

                        <p className={classes.hh}> Talent Management ➢<span className={classes.clr}>Services</span></p>


                

                        <p className={classes.hhh}>We empower organizations to optimize their human capital for sustained success. Our range of services spans the entire employee lifecycle, ensuring that businesses attract, nurture, and retain top talent</p>
                    </div>
                </div>
                <div className={classes.frist}>
                    <div className={classes.points}>
                        <Link to="/hrStrategy" className={classes.point1}>•HR Strategy</Link>
                        <Link to="/talentmanagement" className={classes.point1}>•Talent Management</Link>
                        <Link to="/org" className={classes.point1}>•Organisational Development</Link>
                        <Link to="/hrservicesdelivery" className={classes.point1}>•HR Service Delivery</Link>

                    </div>

                </div>
                <div className={classes.second}>
                    <div className={classes.container1}>

                    </div>
                    <div className={classes.description1}>
                        <h1 className={classes.hr1}>Talent Management- Services</h1>
                        <p className={classes.hr2}>Explore our tailored <span className={classes.clr2}>Talent Management </span><span className={classes.clr3}>hub services encompassing organisation</span></p>

                        <p className={classes.hr3}>Your people are your greatest asset. Our talent management solutions are geared towards optimizing every aspect of the employee lifecycle. We assist in refining talent acquisition processes, implementing strategic development initiatives, and fostering a culture of continuous learning. Ensure your organization attracts, retains, and nurtures top talent for long-term success.</p>

                        {/* <button className={classes.cB}>
                            <Link to="/contact" className={classes.cB}>Contact Us</Link>
                        </button> */}
                    </div>
                </div>

                <div className={classes.third}>
                    <p className={classes.third1}>Recruitment and Selection:</p>

                    <p className={classes.third2}>Identify and acquire the best-fit talent for your organization through strategic recruitment processes and meticulous selection criteria.</p>
                    <p className={classes.third3}>Compensation and Benefits Design:</p>

                    <p className={classes.third4}>Craft competitive and enticing compensation packages that attract top-tier candidates while ensuring fair and motivating reward structures.</p>
                    <p className={classes.third3}>On-boarding:</p>
                    <p className={classes.third4}>Implement effective programs to acknowledge and celebrate employee contributions, fostering a culture of appreciation.</p>
                    <p className={classes.third3}>Rewards and Recognition:</p>
                    <p className={classes.third4}>Harness the power of data with our HR Audit Metrics and Analytics solutions. We conduct thorough assessments of your HR processes, providing valuable insights and metrics to enhance decision-making and optimise resource allocation.</p>
                    <p className={classes.third3}>Performance Management:</p>
                    <p className={classes.third4}>Establish robust performance evaluation systems that align individual achievements with organizational goals.</p>
                    <p className={classes.third3}>Competency Modelling:</p>
                    <p className={classes.third4}>Define the skills, behaviors, and knowledge essential for success in each role, aiding in talent development and performance assessment.</p>
                    <p className={classes.third3}>Talent Review:</p>
                    <p className={classes.third4}>Conduct in-depth assessments to identify high-potential employees and facilitate strategic talent planning.</p>
                    <p className={classes.third3}>Assessments:</p>
                    <p className={classes.third4}>Utilize various evaluation tools to measure skills, competencies, and potential, aiding in informed decision-making.</p>
                    <p className={classes.third3}>Employee Motivation:</p>
                    <p className={classes.third4}>Implement strategies to boost employee morale, job satisfaction, and overall motivation.</p>
                    <p className={classes.third3}>Career Management:</p>
                    <p className={classes.third4}>Empower employees to navigate and plan their career paths within the organization, fostering a sense of purpose and progression.</p>
                    <p className={classes.third3}>Learning and Development:</p>
                    <p className={classes.third4}>Provide continuous learning opportunities to enhance employee skills and knowledge, aligning with organizational objectives.</p>
                    <p className={classes.third3}>Mentoring:</p>
                    <p className={classes.third4}>Facilitate mentorship programs that foster knowledge transfer, skill development, and professional growth.</p>
                    <p className={classes.third3}>Succession Management:</p>
                    <p className={classes.third4}>Identify and groom internal talent to fill key leadership roles, ensuring a seamless transition in times of change.</p>
                    <p className={classes.third5}>At Job Bridge India, we understand that a robust Talent Management strategy is the cornerstone of organizational success. Partner with us to unlock the full potential of your workforce and drive sustainable growth.</p>
                </div>
                <br></br>
                <div className={classes.nine}>

                    <div className={classes.nine1}>
                        <div className={classes.nine2}>
                            <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
                            <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
                        </div>

                    </div>

                </div>

            </div>
            <Footer />
        </>
    )
}

export default Talentmanagement