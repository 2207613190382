// import classes from "./Hero.module.css";
// import { Link } from 'react-router-dom'

// const Hero = () => {
//     return (
//         <div className={classes.container}>
//             <div className={classes.innertext} >
//                 <h1>There Are <span className={classes.highlight}>93,178</span> </h1>
//                 <h1>Postings Here For you!</h1>
//                 <p className={classes.find}>Find Jobs, Employment & Career Opportunities</p>
//             </div>

//             <Link to='contact'><button className={classes.enquiryBtn}>Enquiry Now</button></Link>
//         </div>
//     );
// };
// export default Hero;


import React, { useState, useEffect } from 'react';
import classes from "./Hero.module.css";
import { Link } from 'react-router-dom';

const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        'https://img.freepik.com/premium-photo/closeup-handshake-business-partners_709984-367.jpg',
        'https://howtostartadulting.com/wp-content/uploads/2020/09/shutterstock_743911858.jpg',
        'https://rndpoint.com/wp-content/uploads/2022/08/discussing-cooperation-with-business-partner-2021-09-24-04-04-28-utc-scaled-1.jpg'
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(timer);
    }, []);

    return (
        <div className={classes.container}>
            
            <div className={classes.slider} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={classes.slide}
                        style={{ backgroundImage: `url(${slide})` }}
                    ></div>
                ))}
            </div>
            <div className={classes.innertext}>
                <h1>There Are <span className={classes.highlight}>93,178</span> </h1>
                <h1>Postings Here For you!</h1>
                <p className={classes.find}>Find Your Dream Jobs, Employment & Career Opportunities</p>
                <Link to='contact'><button className={classes.enquiryBtn}>Enquiry Now</button></Link>
            </div>
            
            
        </div>
    );
};

export default Hero;
