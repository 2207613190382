import Navbar from "../NAVBAR/Navbar"
import AboutPage from "../ABOUT/AboutPage";
import Footer from "../FOOTER/Footer";
import Contact from "../CONTACT/Contact";
import Watsapp from "../WATSAPP/Watsapp";

const About = () =>{
    return(
        <>
        <Navbar/>
        <AboutPage/>
        <Contact/>
        <Watsapp/>
        <Footer/>
        </>
    )
}
export default About;