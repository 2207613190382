// import React from 'react'
// import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import DP from "../NAVBAR/IMAGE/LOGO.png";
// import classes from './Watsapp.module.css';

// const Watsapp = () => {
//     return(
//         <FloatingWhatsApp 
//         phoneNumber='8484890029'
//         accountName="Job Bridge India"
//         avatar={DP}
//         />
//     );
// };
// export default Watsapp;
import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import DP from "../NAVBAR/IMAGE/LOGO.png";
import classes from './Watsapp.module.css';

const Watsapp = () => {
    return (
        <div className={classes.container}>
            <FloatingWhatsApp
                phoneNumber='8484890029'
                accountName="Job Bridge India"
                avatar={DP}
                className={classes.chatbox}
                chatMessage="Hello! How can we assist you today?"
                placeholder="Type your message..."
                statusMessage="Typically replies within a few minutes"
                buttonClassName={classes.button}
                avatarClassName={classes.avatar}
                closeButtonClassName={classes.closeButton}
            />
        </div>
    );
};

export default Watsapp;
