import { useEffect } from 'react';
import React from 'react'
import classes from "./Terms.module.css";
import Navbar from '../NAVBAR/Navbar';
import Footer from '../FOOTER/Footer';

const Term = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <div className={classes.description}>

                    <h1 className={classes.p1}>Term and Conditions</h1>
                    <p className={classes.p2}>Before using our website</p>

                </div>
            </div>
            <div className={classes.conatiner2}>
                <div className={classes.ctr}>
                <h2 className={classes.p3}>Overview</h2>
                <p className={classes.p4}>This website is operated by Job Bridge India IT Consulting Services Pvt Ltd. Throughout the site, the terms "we", "us" and "our" refer to Job Bridge India IT Consulting Services. Job Bridge India offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                <p className={classes.p4}>By visiting our site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions ("Terms of Service", "Terms"), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.</p>
                <p className={classes.p4}>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                <p className={classes.p4}>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>

                <h2 className={classes.p3}>Use of the website</h2>
                <p className={classes.p4}>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
                <p className={classes.p4}>This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, please refer to our privacy policy for details of what data we collect and how it is used.</p>
                <h2 className={classes.p3}>Intellectual property rights</h2>
                <p className={classes.p4}>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
                <p className={classes.p4}>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</p>
                <h2 className={classes.p3}>User-generated content</h2>
                <p className={classes.p4}>Any material that you submit to this website, including but not limited to comments, feedback, suggestions, or ideas, will be deemed to be non-confidential and non-proprietary. We shall be entitled to use such material for any purpose without compensation to you.</p>
                <p className={classes.p4}>You warrant that any material you submit to the website does not infringe any third party intellectual property rights, and that you will indemnify us against any claims arising from such infringement.</p>
                <h2 className={classes.p3}>Links to other websites</h2>
                <p className={classes.p4}>This website may contain links to other websites which are not under the control of our company. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                <h2 className={classes.p3}>Limitation of liability</h2>
                <p className={classes.p4}>We will not be liable for any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                <h2 className={classes.p3}>Governing law</h2>
                <p className={classes.p4}>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India.</p>
                <h2 className={classes.p3}>Personal information</h2>
                <p className={classes.p4}>Your submission of personal information through the site is governed by our Privacy Policy. To view our Privacy Policy.</p>
                <h2 className={classes.p3}>Prohibited uses</h2>
                <p className={classes.p4}>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
                <h2 className={classes.p3}>Changes to terms of service</h2>
                <p className={classes.p4}>You can review the most current version of the Terms of Service at any time at this page.</p>
                <p className={classes.p4}>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                <h2 className={classes.p3}>Contact information</h2>
                <p className={classes.p4}>Questions about the Terms of Service should be sent to us at:</p>
                <p className={classes.last3}>Call Us:  <a href="tel:+91 8484890029" className={classes.t1}> +91 8484890029</a>
                   
                </p>
                <p className={classes.last3}>Email Us: <a href="mailto:hrdocs@jobbridgeindia.com" className={classes.t1}>hrdocs@jobbridgeindia.com</a>
                    

                </p>
                <p className={classes.p4}>Last Update: 01-Aug-2024</p>
                </div>
                
            </div>


            <Footer />
        </>

    )
}

export default Term