import { Link } from "react-router-dom";
import classes from "../CONTACT/Contact.module.css";

const Contact = () => {
    return (
        <>
            {/* <div className={classes.container}>
             <button><Link to="/enquiry" className={classes.lnkbtn}>Enquiry Now</Link></button>
         </div> */}
        </>
    );
};
export default Contact;