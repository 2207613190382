// import Navbar from "../NAVBAR/Navbar";
// import classes from "./Login.module.css";
// import img from "./Image/login.jpg"
// import { useState } from "react";
// import axios from "axios"
// import { Navigate, useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { Link } from 'react-router-dom'

// const Login = () => {
//     const [showPassword, setShowPassword] = useState(false)

//     const [data, setData] = useState({
//         email: '',
//         password: '',
//     });

//     const handleCheckBox = () => {
//         setShowPassword(!showPassword)
//     }

//     const handleChange = (e) => {
//         setData({ ...data, [e.target.name]: e.target.value })
//     }
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const res = await axios.post("http://localhost:8800/login", data)
//         //    const res = await axios.post("https://jobbridgeindia.com/login", data)
            
//             if (res.data) {
//                 console.log(res.data[0].NAME)
//                 Cookies.set('loginSuccess', res.data[0].NAME, { expires: (1 / 1440) * 60 }, { secure: true })
//                 Cookies.set('loginSuccess2', res.data[0].EMAIL, { expires: (1 / 1440) * 60 }, { secure: true })
//                 navigate("/")
//             } else {
//                 alert("Invalid Username or Password");
//             }
//         } catch (err) {
//             console.log(err)
//         }
//     }

//     return (
//         <>
//             <Navbar />
//             <div className={classes.container}>
//                 <div className={classes.card}>
//                     <div className={classes.left}>
//                         <img src={img} alt="jobridge" />
//                     </div>
//                     <div className={classes.right}>
//                         <center><h1 className={classes.head1}>LOGIN</h1></center>

//                         <form onSubmit={handleSubmit}>
//                             <input type="email" name="email" onChange={handleChange} placeholder="Enter Your Email Id" />
//                             <input type={showPassword ? 'text' : 'password'} name="password" onChange={handleChange} placeholder="Enter Your Password" />

//                             <div className={classes.showPass}>
//                                 <input type="checkbox" checked={showPassword} onChange={handleCheckBox}></input>
//                                 <label>Show Password</label>
//                             </div>

//                             <button type="submit">Submit</button>
//                             <p>Don't have an account ?. <Link to='/signup'>Register</Link></p>
                            

//                         </form>
                    
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };
// export default Login;



import Navbar from "../NAVBAR/Navbar";
import classes from "./Login.module.css";
import img from "./Image/login.jpg"
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from 'react-router-dom';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [recoveredPassword, setRecoveredPassword] = useState('');
    
    const [data, setData] = useState({
        email: '',
        password: '',
    });
    
    const handleCheckBox = () => {
        setShowPassword(!showPassword);
    };
    
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    
    const navigate = useNavigate();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post("https://jobbridgeindia.com/login", data);
            if (res.data) {
                console.log(res.data[0].NAME);
                Cookies.set('loginSuccess', res.data[0].NAME, { expires: (1 / 1440) * 60 }, { secure: true });
                Cookies.set('loginSuccess2', res.data[0].EMAIL, { expires: (1 / 1440) * 60 }, { secure: true });
                navigate("/");
            } else {
                alert("Invalid Username or Password");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleForgotPasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post("https://jobbridgeindia.com/forgotpassword", { email: forgotPasswordEmail });
            if (res.data.success) {
                setRecoveredPassword(res.data.password);
            } else {
                alert("Email not found. Please check the email address.");
            }
        } catch (err) {
            console.log(err);
            alert("An error occurred. Please try again later.");
        }
    };
    
    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <div className={classes.card}>
                    <div className={classes.left}>
                        <img src={img} alt="jobridge" />
                    </div>
                    <div className={classes.right}>
                        <center><h1 className={classes.head1}>LOGIN</h1></center>
                        
                        {!showForgotPassword ? (
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    placeholder="Enter Your Email Id"
                                />
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    onChange={handleChange}
                                    placeholder="Enter Your Password"
                                />
                                
                                <div className={classes.showPass}>
                                    <input
                                        type="checkbox"
                                        checked={showPassword}
                                        onChange={handleCheckBox}
                                    />
                                    <label>Show Password</label>
                                </div>
                                
                                <button type="submit">Submit</button>
                                <p>
                                    Don't have an account? <Link to='/signup'>Register</Link>
                                </p>
                                <p>
                                    <button type="button" onClick={() => setShowForgotPassword(true)}>Forgot Password?</button>
                                </p>
                            </form>
                        ) : (
                            <form onSubmit={handleForgotPasswordSubmit}>
                                <input
                                    type="email"
                                    value={forgotPasswordEmail}
                                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                                    placeholder="Enter Your Email Id"
                                />
                                <button type="submit">Recover Password</button>
                                {recoveredPassword && (
                                    <p>Your password is: {recoveredPassword}</p>
                                )}
                                <p>
                                    <button type="button" onClick={() => setShowForgotPassword(false)}>Back to Login</button>
                                </p>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;