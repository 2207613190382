import classes from "./JobDetails.module.css";
import Navbar from "../../NAVBAR/Navbar";
import Footer from "../../FOOTER/Footer";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import img1 from "../Jobicon/portfolio.png"
import img2 from "../Jobicon/location.png"
import img3 from "../Jobicon/clock.png"
import img4 from "../Jobicon/money.png"
import { Link } from "react-router-dom";

const JobDetails = () =>{
    const [jobs,setJobs]=useState([])

    const getJobDetails = async (ID) =>{
        console.log(ID)
        try{
            const res = await axios.get("https://jobbridgeindia.com/getDetails",{params:{ID}})
            console.log(res)
            setJobs(res.data)

        }catch(err){
            console.log(err);
        }
    };

    // Define the calculateTimeDifference function inside the Featured component
  const calculateTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const targetTime = new Date(timestamp);
    const difference = currentTime - targetTime;
    const seconds = Math.floor(difference / 1000);

    if (seconds < 86400) {
      // Less than 1 day
      return `${Math.floor(seconds / 3600)} hours ago`;
    } else {
      // 1 day or more
      return `${Math.floor(seconds / 86400)} days ago`;
    }
  };

    useEffect(()=>{
        let ID = Cookies.get("D_ID")
        getJobDetails(ID)
    },[]);
    
    return(
        <>
        <Navbar />
        <div className={classes.container}>
        <div className={classes.subContainer}>  
                {jobs && jobs.map((item,index)=>(
                    <div className={classes.card} key={index}>
                    <div className={classes.left}>
                        <img src={`https://jobbridgeindia.com/Uploads/`+ item.JOB_ICON} alt="JOBRIDGE" />
                    </div>
                    <div className={classes.right}>
                        <p className={classes.bold}>{item.POSITION_OPEN}, {item.JOB_TITLE}</p>
                        <div className={classes.row}><img src={img1} /> <p>{item.JOB_TITLE}</p> <img src={img2} /> <p>{item.CITY}, {item.STATE}</p> <img src={img3} /><p>{calculateTimeDifference(item.TIME_INSERTION)}</p> <img src={img4} /><p>{item.SALARY}</p></div>
                        <div className={classes.row}><button className={classes.high1}>{item.JOB_TIME}</button> <button className={classes.high2}>{item.JOB_CATEGARY}</button> <button className={classes.high3}>{item.HIRING}</button> </div>
                    </div>
                </div>
                ))}
            </div>
            <div className={classes.subContainer2}>
                <Link className={classes.applybtn} to="/applyForm">Apply Now</Link>
            </div>
        </div>
        
        <div className={classes.discription_container}>
        {jobs && jobs.map((item,index)=>(
            <div className={classes.left} key={index}>
            <section className={classes.column}>
                <h1>Job Description</h1>
                <p>{item.DISCRIPTION}</p>
            </section>
            <section className={classes.column}>
                <h1>Key Responsibilities</h1>
                {item.KEY1 && <p>• {item.KEY1}</p>}
                {item.KEY2 && <p>• {item.KEY2}</p>}
                {item.KEY3 && <p>• {item.KEY3}</p>}
                {item.KEY4 && <p>• {item.KEY4}</p>}
                {item.KEY5 && <p>• {item.KEY5}</p>}
            </section>
            <section className={classes.column}>
                <h1>Skill & Experience</h1>
                {item.SKILL1 && <p>• {item.SKILL1}</p>}
                {item.SKILL2 && <p>• {item.SKILL2}</p>}
                {item.SKILL3 && <p>• {item.SKILL3}</p>}
                {item.SKILL4 && <p>• {item.SKILL4}</p>}
                {item.SKILL5 && <p>• {item.SKILL5}</p>}
            </section>
        </div>
        ))}
            
            <div className={classes.right}></div>
        </div>
        <Footer/>
        </>
    );
};
export default JobDetails;