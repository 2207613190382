import { useEffect } from "react";
import React from "react";
import classes from "./Recruitment.module.css"
import { Link } from "react-router-dom"
import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";
import j2 from "./Images/job1.jpeg";

const ExecutiveSearch = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, []);
  return (
    <>
      <Navbar />
      <div className={classes.maincontainer}>

        <div className={classes.background_image}>
          <div className={classes.content_box}>
            <header className={classes.header}>
              <h1 className={classes.main_title}>Job Bridge India</h1>
              <h2 className={classes.subtitle}>
                Recruitment <span className={classes.colorful_text}>➢</span>{" "}
                <span className={classes.multi_color_text}>Executive Search & Leadership Hiring Services</span>
              </h2>

            </header>
            <section className={classes.services_section}>
              <p className={classes.services_description}>
                Our executive search and leadership hiring services are designed to connect you with top-tier talent for C-suite and leadership positions. With a focus on finding exceptional candidates who possess the necessary skills and expertise, we specialize in sourcing, screening, and selecting executives who will drive your organization's success. Trust our comprehensive approach, industry knowledge, and vast network to streamline your executive recruitment process. Partner with us today and secure the leaders who will take your business to new heights.


              </p>
            </section>
          </div>
        </div>
        <div className={classes.staffing_links}>
        <Link to="/StaffingSolution" className={classes.point1}>• Staffing</Link>
      <Link to="/HiringProcess" className={classes.point1}>• Hiring</Link>
      <Link to="/ExecutiveSearch" className={classes.point1}>• Executive Search</Link>
        </div>
        
        <div className={classes.middle}>
          <div className={classes.middle1}>
            <center className={classes.middle5}> Executive Search C-Suite & Leadership Hiring</center>
          
            
            <p className={classes.middle6}>Job Bridge India Executive Search is an exclusive division within Job Bridge India Network. The team at Executive Search focuses on finding the right candidates based on defined mandates to fill in the roles of senior management and leadership teams in large companies.

             The Executive Search team is staffed with senior practice heads who are in touch with the right talent pool who are looking for challenging opportunities.</p>
             {/* <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Us</Link>
                            </button> */}
            <div className={classes.middle3}>
              <p className={classes.middle4}>Career Transition Job Bridge India had opportunities to work closely in designing and offering bespoke solutions for senior management recruitment. As a high-end retained executive search firm, our executive search consultants diligently deal with confidentiality and sensitivity on regular basis. One more reason why our clients approach us for supporting their “Outplacement” projects is to avail of the large industry connections TMI has so that their transitioning employees can look to a higher probability of finding their next respectable job.</p>

            </div>

          </div>

        </div>
        

        <div className={classes.nine}>

          <div className={classes.nine1}>
            <div className={classes.nine2}>
              <p className={classes.nine3}>Ready to find the perfect talent for your organisation? Reach out to us today and let us handle your recruitment needs.</p>
              <button className={classes.nine4}>
                                <Link to="/contact" className={classes.btnLink}>Contact Now</Link>
                            </button>
            </div>

          </div>

        </div>
      </div>
      <Footer />
    </>

  );
};

export default ExecutiveSearch;
